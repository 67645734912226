import React from "react";
import { Redirect } from "react-router-dom";

// logo
import { useUserAuthState } from "../../context/UserAuthContext";

export default function Error() {
  var { isAuthenticated } = useUserAuthState();
  const isAuth = isAuthenticated()
  return (
    isAuth ?
      <Redirect to={process.env.PUBLIC_URL + '/'} />
      :
      <Redirect to={process.env.PUBLIC_URL + '/login'} />
  );
}
