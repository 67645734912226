import React from "react";
import {
  Grid
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

//This page is for app to show In App Purchase cancelation process for iOS device
function CancelIosIAP(props) {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.mainContainer}>
        <h3 className={classes.mainTitle}>How do I cancel iTunes billing for TrakMeet?</h3>
        <p>To cancel your iTunes billing for TrakMeet, open iTunes on your Apple mobile device or computer and follow the steps below. If you'd prefer step-by-step instructions with screenshots, please visit Apple's <a href="https://support.apple.com/en-gb/HT202039">View, change, or cancel your subscriptions</a> article. (Note: As per Apple's cancel policy, your membership might renew if you don't turn off Automatic Renewal more than 8 hours before your renewal date.)</p>
        <p className={classes.normalBoldText}><span className={classes.welcomeText}>Note: </span>The ability to cancel iTunes billing for TrakMeet on an Apple TV is not available.</p>

        <h3 className={classes.subTitle}>Cancel TrakMeet through your iPhone, iPad or iPod touch</h3>
        <ul className={classes.mainList}>
          <li>On the Home screen, tap <span className={classes.normalBoldText}>Settings</span>.</li>
          <li>Tap <span className={classes.normalBoldText}>iTunes & App Store</span>.</li>
          <li>Tap your Apple ID.</li>
          <li>Tap <span className={classes.normalBoldText}>View Apple ID</span>. You may need to sign in.</li>
          <li>Under <span className={classes.normalBoldText}>Subscriptions</span>, tap <span className={classes.normalBoldText}>Manage</span>.</li>
          <ul>
            <li>If you don't see a TrakMeet subscription and are still being charged, make sure that you're signed in with the correct Apple ID. If so, you might have subscribed directly through TrakMeet and not through iTunes. In this case, please use this article to cancel your subscription.</li>
          </ul>
          <li>Tap TrakMeet.</li>
          <li>Turn off <span className={classes.normalBoldText}>Automatic Renewal</span> to cancel a subscription. Your subscription will stop at the end of the current billing cycle.</li>
          <li>To resume your TrakMeet account with TrakMeet billing, you can restart your membership from the TrakMeet App.</li>
        </ul>

        <h3 className={classes.subTitle}>Cancel TrakMeet through your computer</h3>
        <ul className={classes.mainList}>
          <li>Download or open iTunes.</li>
          <li>Select <span className={classes.normalBoldText}>Sign In</span> and enter your Apple ID.</li>
          <li>Select your name, then select <span className={classes.normalBoldText}>Account Info</span> from the drop-down menu.</li>
          <li>Enter your password again.</li>
          <li>On the <span className={classes.normalBoldText}>Account Information</span> screen, scroll to the Settings section.</li>
          <li>To the right of <span className={classes.normalBoldText}>Subscriptions</span>, select <span className={classes.normalBoldText}>Manage</span>.</li>
          <ul>
            <li>If you don't see a TrakMeet subscription and are still being charged, make sure that you're signed in with the correct Apple ID. If so, you might have subscribed directly through TrakMeet and not through iTunes. In this case, please use this article to cancel your subscription.</li>
          </ul>
          <li>In the TrakMeet section, select <span className={classes.normalBoldText}>Edit</span>.</li>
          <li>Turn off <span className={classes.normalBoldText}>Automatic Renewal</span> to cancel a subscription. Your subscription will stop at the end of the current billing cycle.</li>
          <li>To resume your TrakMeet account with TrakMeet billing, you can restart your membership from the TrakMeet App.</li>
        </ul>
      </div>
    </Grid>
  );
}

export default withRouter(CancelIosIAP);
