import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import PrivacyPolicy from "../pages/privacyPolicy";
import TermsAndConditions from "../pages/termsAndConditions";
import CookiePolicy from "../pages/cookiePolicy";
import AcceptableUsePolicy from "../pages/acceptableUsePolicy";
import CancelAndroidIAP from "../pages/cancelAndroidIAP";
import CancelIosIAP from "../pages/cancelIosIAP";
import SwitchPlanIos from "../pages/switchPlanIos";
import config from "../config";

// context
import { useUserAuthState } from "../context/UserAuthContext";

//Global css
import '../static/css/global.css'

export default function App() {
  // global

  axios.defaults.baseURL = config.apiBaseURL;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["language"] = config.language;
  const authorization = localStorage.getItem("authorization");
  if (authorization) {
    axios.defaults.headers.common["auth_token"] = authorization;
  }

  var { isAuthenticated } = useUserAuthState();
  const isAuth = isAuthenticated()
  return (
    <BrowserRouter>
      <Switch>
        <Route path={process.env.PUBLIC_URL + "/verification/:token"} exact component={ResetPassword} />
        <Route path={process.env.PUBLIC_URL + "/reset-password"} exact component={ResetPassword} />
        <Route exact path={process.env.PUBLIC_URL + "/"} render={() => <Redirect to={process.env.PUBLIC_URL + "/app/dashboard"} />} />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/app"}
          render={() => <Redirect to={process.env.PUBLIC_URL + "/app/dashboard"} />}
        />
        <PrivateRoute path={process.env.PUBLIC_URL + "/app"} component={Layout} />
        <PublicRoute path={process.env.PUBLIC_URL + "/login"} exact component={Login} />
        <PublicRoute path={process.env.PUBLIC_URL + "/forgot-password"} exact component={ForgotPassword} />
        <Route path={process.env.PUBLIC_URL + "/privacy"} exact component={PrivacyPolicy} />
        <Route path={process.env.PUBLIC_URL + "/terms"} exact component={TermsAndConditions} />
        <Route path={process.env.PUBLIC_URL + "/cookie-policy"} exact component={CookiePolicy} />
        <Route path={process.env.PUBLIC_URL + "/acceptable-use-policy"} exact component={AcceptableUsePolicy} />
        <Route path={process.env.PUBLIC_URL + "/cancel-android-IAP"} exact component={CancelAndroidIAP} />
        <Route path={process.env.PUBLIC_URL + "/cancel-ios-IAP"} exact component={CancelIosIAP} />
        <Route path={process.env.PUBLIC_URL + "/switch-paln-ios"} exact component={SwitchPlanIos} />

        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################
  // Routes which can be accessible after login only
  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuth ? (
            React.createElement(component, props)
          ) : (
            <Redirect to={process.env.PUBLIC_URL + "/login"} />
          )
        }
      />
    );
  }
  // Routes which can be accessible before login only
  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuth ? (
            <Redirect
              to={{
                pathname: process.env.PUBLIC_URL + "/"
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
