import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    container: {
    },
    mainPageTitle: {
        marginTop: "18px",
        fontSize: "24px",
        marginLeft: "10px",
        fontWeight: 400,
    },
}))
