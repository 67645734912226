import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    Box,
    InputAdornment,
    TextField as Input,
    CircularProgress,
    Switch,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Button,
    MenuItem,
    Menu,
    TextField,
    FormLabel,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import useStyles from './styles';
// Icons
import {
    ArrowDropDown,
    Search as SearchIcon,
    Add as AddIcon,
    Edit,
    Delete,
} from '@material-ui/icons';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import { createAdminUser, deleteAdminUser, getAdminUsersRequest, handleBlockAdminUsers, sendResetRequest, updateAdminUser, useAdminUsersState } from '../../context/AdminUsersContext';
import { Typography } from '../../components/Wrappers/Wrappers';
import config from '../../config';
import joi from 'joi-browser';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import alertify from 'alertifyjs';
import { validate } from '../../common/common';

//Table headings
const headCells = [
    { numeric: true, disablePadding: false, label: '#', sort: false },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'EMAIL',
        sort: false,
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'TYPE',
        sort: false,
    },
    {
        id: 'block',
        numeric: true,
        disablePadding: false,
        label: 'UNBLOCK/BLOCK',
        center: true,
        sort: false,
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
        sort: false,
        center: true
    },
]

//It will create table heading
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    return <TableHead>
        <TableRow>
            {headCells.map((headCell, index) => (
                <TableCell
                    width={headCell.width}
                    key={index}
                    align={headCell.center ? 'center' : headCell.numeric ? 'left' : 'right'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    {headCell.sort ?
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                        >
                            <Typography
                                noWrap
                                weight={'medium'}
                                variant={'body2'}
                            >
                                {headCell.label}
                            </Typography>
                        </TableSortLabel>
                        :
                        <Typography
                            noWrap
                            weight={'medium'}
                            variant={'body2'}
                        >
                            {headCell.label}
                        </Typography>
                    }
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
}


//Users component
const Users = props => {
    const classes = useStyles();
    const context = useAdminUsersState();

    const [order, setOrder] = useState('desc');
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('created_date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [error, setError] = useState("");
    const [errorField, setErrorField] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [passwordView, setPasswordView] = useState(false);
    const [modalType, setModalType] = useState(1);
    const [adminData, setAdminData] = useState({
        admin_email: "",
        admin_password: "",
        admin_type: '0',
        admin_id: ""
    });

    const [anchorEl2, setAnchorEl2] = useState(null);
    const openDropdown2 = Boolean(anchorEl2);

    const handleClick2 = (event, data) => {
        setAnchorEl2(event.currentTarget);
        setAdminData({
            admin_email: data.email,
            admin_password: "",
            admin_type: data.is_admin,
            admin_id: data.user_id
        })
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };


    const changeHandler = (e) => {
        const { value, name } = e.target;
        setAdminData((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleClosemodal = () => {
        setOpenModal(false);
    };

    const handleOpenmodal = (edit, data) => {
        handleClose2();
        setOpenModal(true);
        setModalType(edit ? 2 : 1);
        if (edit) {
        } else {
            setAdminData({
                admin_email: "",
                admin_password: "",
                admin_type: "0",
                admin_id: ""
            });
        }
    };

    const validateFormData = (body) => {
        let valid_return = false;
        let schema = joi.object().keys({
            email: joi.string().trim().email({ minDomainAtoms: 2 }).regex(config.emailRegex).required(),
            password: modalType == 1 ? joi.string().trim().min(8).required() : joi.string().trim().min(8).optional().allow(""),
            type: joi.number().required().disallow(0).label('admin type'),
            admin_id: joi.number().optional().allow("")
        })
        joi.validate(body, schema, async (err, value) => {
            if (err) {
                if (err.details[0].message !== error || error.details[0].context.key !== errorField) {
                    let errorLog = validate(err)
                    setError(errorLog.error)
                    setErrorField(errorLog.errorField)
                }
            } else {
                setError("")
                setErrorField("")
            }
            return err ? valid_return = false : valid_return = true
        });
        return valid_return;
    };

    const onCreateButtonClick = async () => {
        const reqData = {
            "email": adminData.admin_email,
            "password": adminData.admin_password,
            "type": adminData.admin_type ? Number(adminData.admin_type) : 0
        };
        const valid = validateFormData(reqData);
        if (valid) {
            setLoading(true);
            const response = await createAdminUser(context.setAdminUsers, reqData);
            if (response.status == 200 && response.data.code == 1) {
                handleClosemodal();
                await getAdminUsersRequest(context.setAdminUsers);
                setLoading(false);
            } else {
                setLoading(true);
            }
        }
    };

    const onUpdateButtonClick = async () => {
        const reqData = {
            "email": adminData.admin_email,
            "type": Number(adminData.admin_type),
            "admin_id": Number(adminData.admin_id)
        };
        const valid = validateFormData(reqData);
        if (valid) {
            setLoading(true);
            const response = await updateAdminUser(context.setAdminUsers, reqData);
            if (response.status == 200 && response.data.code == 1) {
                handleClosemodal();
                await getAdminUsersRequest(context.setAdminUsers);
                setLoading(false);
            } else {
                setLoading(true);
            }
        }
    };

    const onDeleteIconClick = async (e, data) => {
        setAdminData({
            admin_email: data.email,
            admin_password: "",
            admin_type: data.is_admin,
            admin_id: data.user_id
        })
        e.preventDefault();
        handleClose2();
        alertify.confirm("Are you sure you want to delete?", async (status) => {
            if (status) {
                await onDeleteAdminClick();
                await getAdminUsersRequest(context.setAdminUsers);
            }
            else {
                closeDeleteModal();
            }
        }).setHeader('<em>TrakMeet</em> ').set('labels', { ok: 'OK', cancel: 'CANCEL' });
    }

    const onDeleteAdminClick = async (id) => {
        setLoading(true);
        const reqData = {
            admin_id: adminData.admin_id
        }
        await deleteAdminUser(context.setAdminUsers, reqData);
        setLoading(false);
    };

    const closeDeleteModal = () => {
        setAdminData({
            admin_email: "",
            admin_password: "",
            admin_type: "",
            admin_id: ""
        });
    };

    const sendResetPassLink = async (data) => {
        const reqData = {
            email: data.email
        };
        const res = await sendResetRequest(context.setAdminUsers, reqData);
        handleClose2();
    };

    const blockAdmin = async (item) => {
        const reqData = {
            user_id: item.user_id,
            is_banned: item.is_banned == 0 ? 1 : 0,
        }
        await handleBlockAdminUsers(context.setAdminUsers, reqData);
        await getAdminUsersRequest(context.setAdminUsers);
    };

    useEffect(() => {
        getAdminUsersRequest(context.setAdminUsers);
    }, []);

    return (
        <Grid container spacing={3}>
            <span className={classes.mainPageTitle}>Admin Management</span>
            <Grid item xs={12}>
                <Widget inheritHeight>
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Box style={{ margin: '0 12px 0 0' }}>
                            <Button onClick={() => handleOpenmodal(false)} variant={'contained'} color={'primary'}>
                                <Box mr={1} display={'flex'}>
                                    <AddIcon />
                                </Box>
                                Create Admin
                            </Button>
                        </Box>
                        {/* <Box
                            justifyContent={'space-between'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-end'}
                            >
                                <Input
                                    id="search-field"
                                    label="Search"
                                    margin="dense"
                                    variant="outlined"
                                    // onChange={e => handleSearch(e)}
                                    // value={searchVal}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box> */}
                    </Box>
                </Widget>
            </Grid>
            {!context.adminUsers.isLoaded ? (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100vw'}
                    height={'calc(100vh - 200px)'}
                >
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    rowCount={context.adminUsers.users.length}
                                />
                                <TableBody>
                                    {
                                        context.adminUsers.users.length > 0 ?
                                            context.adminUsers.users.map((item, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell >
                                                            <Typography
                                                                variant={'body2'}
                                                            >
                                                                {(page * rowsPerPage) + index + 1}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                display={'flex'}
                                                                alignItems={'center'}
                                                            >
                                                                <Typography
                                                                    variant={'body2'}
                                                                >
                                                                    {item.email}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                display={'flex'}
                                                                alignItems={'center'}
                                                            >
                                                                <Typography
                                                                    variant={'body2'}
                                                                >
                                                                    {item.is_admin == 1 ? "Admin" : item.is_admin == 2 ? "Super Admin" : "-"}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                display={'flex'}
                                                                alignItems={'center'}
                                                                justifyContent={'center'}
                                                            >
                                                                <Switch
                                                                    checked={item.is_banned == 0 ? false : true}
                                                                    onChange={() => blockAdmin(item)}
                                                                    color="primary"
                                                                    name="checkedB"
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Button
                                                                    id="demo-positioned-button"
                                                                    color="primary"
                                                                    style={{ cursor: 'pointer', width: "100px" }}
                                                                    variant='contained'
                                                                    aria-controls={openDropdown2 ? 'demo-positioned-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={openDropdown2 ? 'true' : undefined}
                                                                    onClick={(event) => handleClick2(event, item)}
                                                                >
                                                                    <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "start" }}>
                                                                        Action
                                                                        <ArrowDropDown />
                                                                    </div>
                                                                </Button>
                                                                <Menu
                                                                    id="demo-positioned-menu"
                                                                    aria-labelledby="demo-positioned-button"
                                                                    anchorEl={anchorEl2}
                                                                    open={openDropdown2}
                                                                    onClose={handleClose2}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <MenuItem>
                                                                        <Box
                                                                            style={{ width: "100%" }}
                                                                            display={'flex'}
                                                                            alignItems={'center'}
                                                                            justifyContent={'start'}
                                                                        >
                                                                            <Button
                                                                                color={'primary'}
                                                                                variant={'outlined'}
                                                                                size={'medium'}
                                                                                style={{ marginRight: "10px" }}
                                                                                onClick={() => handleOpenmodal(true, item)}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </Box>
                                                                    </MenuItem>
                                                                    <MenuItem>
                                                                        <Box
                                                                            style={{ width: "100%" }}
                                                                            display={'flex'}
                                                                            alignItems={'center'}
                                                                            justifyContent={'start'}
                                                                        >
                                                                            <Button
                                                                                color={'primary'}
                                                                                variant={'outlined'}
                                                                                size={'medium'}
                                                                                onClick={(e) => onDeleteIconClick(e, item)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </MenuItem>
                                                                    <MenuItem>
                                                                        <Button
                                                                            color={'primary'}
                                                                            variant={'outlined'}
                                                                            size={'medium'}
                                                                            onClick={() => sendResetPassLink(item)}
                                                                        > Send Reset Password Link
                                                                        </Button>
                                                                    </MenuItem>
                                                                </Menu>
                                                            </div>
                                                            {/* <Box
                                                                display={'flex'}
                                                                alignItems={'center'}
                                                                justifyContent={'center'}
                                                            >
                                                                <Button onClick={() => handleOpenmodal(true, item)}>
                                                                    <Edit />
                                                                </Button>
                                                                <Button onClick={(e) => onDeleteIconClick(e, item)}>
                                                                    <Delete />
                                                                </Button>
                                                            </Box> */}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <TableCell
                                                    colSpan={headCells.length}
                                                >
                                                    <Typography
                                                        style={{
                                                            textAlign: 'center',
                                                            padding: '10px 0px',
                                                        }}
                                                        noWrap
                                                        variant={'h4'}
                                                    >
                                                        No Record Found
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={config.defaultRowsPerPage}
                            component="div"
                            count={context.adminUsers.users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        // onPageChange={handleChangePage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            )}

            <Dialog
                open={openModal}
                onClose={() => handleClosemodal()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" style={{ textAlign: 'left' }}>
                    <span style={{ fontSize: '25px' }}> {modalType == 1 ? "Create Admin" : "Update Admin"}</span>
                </DialogTitle>
                <DialogContent >
                    <Input
                        type='email'
                        label='Email'
                        placeholder={"Enter Email"}
                        margin="normal"
                        variant="outlined"
                        name='admin_email'
                        onChange={e => changeHandler(e)}
                        InputProps={{
                            classes: {
                                underline: classes.InputUnderline,
                                input: classes.Input
                            }
                        }}
                        value={adminData.admin_email}
                        fullWidth
                    />
                    {errorField === 'email' && <ErrorMessage error={error} />}
                    {modalType == 1 && <TextField
                        type={passwordView ? 'text' : 'password'}
                        label='Password'
                        placeholder={"Enter Password"}
                        margin="normal"
                        variant="outlined"
                        name='admin_password'
                        onChange={e => changeHandler(e)}
                        InputProps={{
                            classes: {
                                underline: classes.InputUnderline,
                                input: classes.Input
                            }
                        }}
                        value={adminData.admin_password}
                        fullWidth
                    />}
                    {errorField === 'password' && <ErrorMessage error={error} />}
                    <div style={{ marginTop: "10px" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Admin Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={e => changeHandler(e)}
                        >
                            <FormControlLabel name='admin_type' checked={adminData.admin_type == '1'} value="1" control={<Radio />} label="Admin" />
                            <FormControlLabel name='admin_type' checked={adminData.admin_type == '2'} value="2" control={<Radio />} label="Super Admin" />
                        </RadioGroup>
                    </div>
                    {errorField === 'type' && <ErrorMessage error={'Please select an admin type'} />}
                </DialogContent>
                <DialogActions style={{ padding: "10px 24px 20px" }}>
                    <Button
                        variant={"outlined"}
                        color="primary"
                        onClick={() => handleClosemodal()}
                        style={{ cursor: 'pointer' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={"contained"}
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => modalType == 1 ? onCreateButtonClick() : onUpdateButtonClick()}
                    >
                        {loading && <CircularProgress size={26} className={classes.buttonLoader} />}
                        {modalType == 1 ? "Create" : "Update"}
                    </Button>

                </DialogActions>
            </Dialog>
        </Grid >
    )
}

export default withRouter(Users)
