import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import {
  Grid,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Toolbar,
  CircularProgress,
  Button
} from "@material-ui/core";
import { PeopleAlt as PeopleAltIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";

//context
import {
  useDashboardState,
  getRecentlyRegisteredUsersRequest,
  getTotalUsersOnPlatform
} from "../../context/DashboardContext";

//Function to sort data
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

//Table headings
const headCells = [
  { numeric: true, disablePadding: false, label: '#', sort: false },
  { id: "name", numeric: true, disablePadding: false, label: "Name", width: '200px', sort: true },
  { id: "profile_pic", numeric: true, center: true, disablePadding: false, label: "Profile Picture", sort: false },
  { id: "gender", numeric: true, disablePadding: false, label: "Gender", sort: true },
  { id: "date_of_birth", numeric: true, disablePadding: false, label: "Date of birth", sort: true },
  { id: "created_date", numeric: true, disablePadding: false, label: "Registered At", sort: true }
];

//It will create table heading
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            width={headCell.width}
            key={index}
            align={headCell.center ? 'center' : headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  noWrap
                  weight={'medium'}
                  variant={'body2'}
                  style={{ textTransform: 'uppercase' }}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
              :
              <Typography
                noWrap
                weight={'medium'}
                variant={'body2'}
                style={{ textTransform: 'uppercase' }}
              >
                {headCell.label}
              </Typography>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}


const useToolbarStyles = makeStyles(theme => ({
  title: {
    flex: "1 1 100%"
  }
}));

//It will create table toolbar
const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={classes.root}
    >
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
      >
        <Box display={"flex"} className={classes.title}>
          <Typography
            variant="h6"
            color="text"
            colorBrightness={"secondary"}
            id="tableTitle"
            style={{ display: "flex" }}
            block
          >Recent Users</Typography>

        </Box>
        <Box>
          <Button
            style={{ width: '120px' }}
            variant={'contained'}
            color={'primary'}
            onClick={() => props.history.push(process.env.PUBLIC_URL + '/app/users')}
          >View More</Button>
        </Box>
      </Box>

      {/* <Box display={"flex"} className={classes.title}>
        <Typography
          variant="h6"
          color="text"
          colorBrightness={"secondary"}
          id="tableTitle"
          style={{ display: "flex" }}
          block
        >
          Recent Users
            </Typography>
            
      </Box> */}
    </Toolbar>
  );
};

//dashboard component
function Dashboard(props) {
  var classes = useStyles();
  const context = useDashboardState();
  var [users, setBackUsers] = useState(
    context.users.users
  );

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_date");

  //React hook which calls when page loads
  useEffect(() => {
    getTotalUsersOnPlatform(context.setTotalUsers)
    getRecentlyRegisteredUsersRequest(context.setRecentUsers);
  }, []); // eslint-disable-line

  //React hook which calls when context value change
  useEffect(() => {
    setBackUsers(context.users.users);
  }, [context]);

  //Function to store Sort by and property to sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  };
  return (
    <Grid container spacing={3}>
      {!context.users.isLoaded || !users.data ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <Grid item lg={3} sm={6} xs={12}>
            <Widget
              bodyClass={classes.fullHeightBody}
              className={classes.card}
              disableWidgetMenu
              noBodyPadding
            >
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Box className={classes.iconContainer}>
                    <PeopleAltIcon style={{ fontSize: '50px', color: '#ffffff' }} />
                  </Box>

                  <Box className={classes.totalContainer}>
                    <Typography variant="h2" weight="medium">
                      {context.totalUsers.totalUsers.data ? context.totalUsers.totalUsers.data[0].total_users : 0}
                    </Typography>

                    <Typography
                      color="text"
                      variant={"caption"}
                    >
                      Users
                    </Typography>
                  </Box>

                </Grid>
              </Grid>
            </Widget>

          </Grid>

          <Grid item xs={12}>
            <Widget noBodyPadding bodyClass={classes.tableWidget}>
              <EnhancedTableToolbar history={props.history} />
              <div className={classes.tableWrapper}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="recent users"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={users.data.length}
                  />
                  <TableBody>
                    {stableSort(users.data, getSorting(order, orderBy))
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <Typography
                                variant={'body2'}
                              >
                                {index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <Typography
                                  variant={'body2'}
                                >
                                  {row.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                display={"flex"}
                                flexWrap={"nowrap"}
                                alignItems={"center"}
                                style={{ height: '50px', width: '50px', margin: '0 auto' }}
                              >
                                <img src={row.profile_pic} className={classes.profilePicture} alt='profile' />
                              </Box>
                            </TableCell>
                            <TableCell>{row.gender == 1 ? 'male' : row.gender == 2 ? 'female' : 'other'}</TableCell>
                            <TableCell>{moment(row.date_of_birth).format("MM-DD-YYYY")}</TableCell>
                            <TableCell>{moment(row.created_date * 1000).format("MM-DD-YYYY hh:mm:ss A")}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
            </Widget>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withRouter(Dashboard);
