import React, { useEffect, useState } from 'react'
import {
    Grid,
    Box,
    InputAdornment,
    TextField as Input,
    CircularProgress,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    Paper,
    Button
} from '@material-ui/core'
import useStyles from "./styles";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/themes/default.min.css';
import 'alertifyjs/build/css/alertify.min.css';
import joi from 'joi-browser';
import { withRouter } from 'react-router-dom';
import {
    Search as SearchIcon
} from '@material-ui/icons'

// Icons
import {
    ArrowDropDown,
    ArrowDropUp,
} from '@material-ui/icons'
import { getReferralusedUsers, getUsersRequest, useReferralUserState } from '../../context/ReferralUserContext';
import Widget from '../../components/Widget/Widget';
import config from '../../config';
import { Typography } from '../../components/Wrappers/Wrappers';


function EnhancedTableHead(props) {
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ textAlign: "center" }}>#</TableCell>
                <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                <TableCell style={{ textAlign: "center" }}>Referral Code</TableCell>
                <TableCell style={{ textAlign: "center" }}>Total Referral Users</TableCell>
                <TableCell style={{ textAlign: "center" }}>View</TableCell>
                {/* <TableCell style={{ textAlign: "center" }}>Users List</TableCell> */}
            </TableRow>
        </TableHead>
    )
}

const ReferralUser = () => {
    const classes = useStyles();
    const context = useReferralUserState();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchVal, setSearchVal] = useState("");
    const [selectedDropdownUser, setSelectedDropdownUser] = useState(null);
    const [referralUsers, setReferraUsers] = useState(context.referralUser);
    const [userRows, setUsersRows] = useState(context.referralUser.users)
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_date');
    const [page, setPage] = useState(0);

    const [referralCodeUser, setReferralCodeUser] = useState([]);

    useEffect(() => {
        getUsersRequest(context.setReferralUser);
        setReferraUsers(context.referralUser)
        setUsersRows(context.referralUser)
    }, []);

    useEffect(() => {
        setReferraUsers(context.referralUser)
        setUsersRows(context.referralUser)
    }, [context.referralUser.isLoaded]);

    useEffect(() => {
        setReferralCodeUser(context.referralUsedUser.userDetail)
    }, [context.referralUsedUser.isLoaded]);

    useEffect(() => {
        const reqData = {
            "referral_user_id": selectedDropdownUser
        };
        selectedDropdownUser !== null && getReferralusedUsers(context.setReferralUsedUser, reqData)
    }, [selectedDropdownUser]);

    //Function to call when pagination value change
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    //Function to set value of rows per page
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    //Function to sort data
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    };

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map(el => el[0])
    };

    //Function to search
    const handleSearch = e => {
        let value = e.currentTarget.value.replace(/^\s+/, "")
        setSearchVal(value)
        setPage(0)
        const newArr = userRows.users.filter(c => {
            return c.name && (c.name.toLowerCase().includes(value.toLowerCase()) || c.gender.toLowerCase().includes(value.toLowerCase()))
        })
        setReferraUsers({ isLoaded: true, users: newArr });
    };

    return (
        <Grid container spacing={3}>
            <span className={classes.mainPageTitle}>Referral User Management</span>
            <Grid item xs={12}>
                <Widget inheritHeight>
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-end'}
                        >
                            <Input
                                id="search-field"
                                label="Search"
                                margin="dense"
                                variant="outlined"
                                onChange={(e) => handleSearch(e)}
                                value={searchVal}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                </Widget>
            </Grid>
            {!context.referralUser.isLoaded ? (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100vw"}
                    height={"calc(100vh - 200px)"}
                >
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table">
                                <EnhancedTableHead />
                                {referralUsers && referralUsers.users.length > 0 ?
                                    <TableBody>
                                        {stableSort(referralUsers.users, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                                // referralUsers.users.map((item, index) => {
                                                return (
                                                    <>
                                                        <TableRow hover>
                                                            <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                                                            <TableCell style={{ textAlign: "center" }}>{item.name}</TableCell>
                                                            <TableCell style={{ textAlign: "center" }}>{item.user_name}</TableCell>
                                                            <TableCell style={{ textAlign: "center" }}>{item.referral_code}</TableCell>
                                                            <TableCell style={{ textAlign: "center" }}>{item.referral_count}</TableCell>
                                                            <TableCell style={{ textAlign: "center" }}>
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    {
                                                                        selectedDropdownUser == item.user_id ?
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                flexDirection: "row",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <p>Hide users</p>
                                                                                <button
                                                                                    onClick={() => setSelectedDropdownUser(null)}
                                                                                    style={{
                                                                                        backgroundColor: 'transparent',
                                                                                        border: '0px'
                                                                                    }}
                                                                                >
                                                                                    <ArrowDropUp />
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                flexDirection: "row",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <p>View users</p>
                                                                                <button
                                                                                    onClick={() => item.referral_count !== 0 && setSelectedDropdownUser(item.user_id)}
                                                                                    style={{
                                                                                        backgroundColor: 'transparent',
                                                                                        border: '0px',
                                                                                    }}>
                                                                                    <ArrowDropDown />
                                                                                </button>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                        {(selectedDropdownUser == item.user_id) &&
                                                            <TableRow style={{ backgroundColor: "#fafafa" }}>
                                                                <TableCell colSpan={6} style={{ paddingLeft: "20px" }}>
                                                                    <h3>Referral Code Users</h3>
                                                                    <TableContainer className={classes.container}>
                                                                        <Table style={{ border: '1px solid #e6e6e6' }}>
                                                                            <TableHead >
                                                                                <TableRow>
                                                                                    <TableCell style={{ textAlign: "center", fontWeight: 'bold', border: '1px solid #e6e6e6', width: "150px" }}>#</TableCell>
                                                                                    <TableCell style={{ textAlign: "center", fontWeight: 'bold', border: '1px solid #e6e6e6' }}>Name</TableCell>
                                                                                    <TableCell style={{ textAlign: "center", fontWeight: 'bold', border: '1px solid #e6e6e6' }}>Username</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {referralCodeUser.map((item, index) => {
                                                                                    return (
                                                                                        <TableRow>
                                                                                            <TableCell style={{ textAlign: "center", border: '1px solid #e6e6e6' }}>{index + 1}</TableCell>
                                                                                            <TableCell style={{ textAlign: "center", border: '1px solid #e6e6e6' }}>{item.name}</TableCell>
                                                                                            <TableCell style={{ textAlign: "center", border: '1px solid #e6e6e6' }}>{item.user_name}</TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </>
                                                )
                                            })}
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography
                                                    style={{ textAlign: 'center', padding: '10px 0px' }}
                                                    noWrap
                                                    variant={'h4'}>No Record Found</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={config.defaultRowsPerPage}
                            component="div"
                            count={referralUsers.users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            )}
        </Grid>
    )
};
export default withRouter(ReferralUser)