import React from "react";
import { apiCall, displayLog } from "../common/common";

const ReportsContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case "GET_REPORTS_OF_REGISTERED_USERS":
      return {
        isLoaded: true,
        registeredUsersReports: action.payload,
      };
    case "GET_REPORTS_OF_SUBSCRIBED_USERS":
      return {
        isLoaded: true,
        subscribedUsersReports: action.payload,
      };

    default:
      return {
        ...state
      };
  }
};

const ReportsProvider = ({ children }) => {

  const [registeredUsersReports, setReportsOfRegisteredUsers] = React.useReducer(rootReducer, {
    isLoaded: false,
    registeredUsersReports: []
  });
  const [subscribedUsersReports, setReportsOfSubscribedUsers] = React.useReducer(rootReducer, {
    isLoaded: false,
    subscribedUsersReports: []
  });
  return (
    <ReportsContext.Provider value={{ registeredUsersReports, setReportsOfRegisteredUsers, subscribedUsersReports, setReportsOfSubscribedUsers }}>
      {children}
    </ReportsContext.Provider>
  );
};

const useReportsState = () => {
  const context = React.useContext(ReportsContext);
  return context;
};

//API call to get reported registered users
export async function getReportsOfRegisteredUsersRequest(dispatch, reqData) {
  let res = await apiCall('GET', 'GET_REPORTS_OF_REGISTERED_USERS', '/admin/reportedRegisteredUsers', {}, {}, reqData, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_REPORTS_OF_REGISTERED_USERS", payload: res.data });
    }
  }
}

//API call to get reported subscribed users
export async function getReportsOfSubscribedUsersRequest(dispatch, reqData) {
  let res = await apiCall('GET', 'GET_REPORTS_OF_SUBSCRIBED_USERS', '/admin/reportedSubscribedUsers', {}, {}, reqData, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_REPORTS_OF_SUBSCRIBED_USERS", payload: res.data });
    }
  }
}

export { ReportsProvider, ReportsContext, useReportsState };
