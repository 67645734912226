import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  InputAdornment,
  TextField as Input,
  CircularProgress,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel
} from '@material-ui/core'

import Widget from '../../../components/Widget/Widget'
import { Button, Typography } from '../../../components/Wrappers/Wrappers'

import useStyles from "./styles";

import moment from 'moment'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { CSVLink } from "react-csv";  //Generate CSV

//context
import {
  useReportsState,
  getReportsOfSubscribedUsersRequest,
} from "../../../context/ReportsContext";

// Icons
import {
  Search as SearchIcon,
  CalendarTodayOutlined as CalendarTodayOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons'

import config from "../../../config";

//Function to sort data
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

//Table headings
const headCells = [
  { numeric: true, disablePadding: false, label: '#', sort: false },
  { id: "name", numeric: true, disablePadding: false, label: "NAME", width: '200px', sort: true },
  { id: "profile_pic", numeric: true, center: true, disablePadding: false, label: "PROFILE PICTURE", sort: false },
  { id: "gender", numeric: true, disablePadding: false, label: "GENDER", sort: true },
  { id: "date_of_birth", numeric: true, disablePadding: false, label: "DATE OF BIRTH", sort: true },
  { id: 'reason', numeric: true, disablePadding: false, label: 'REASON', sort: true },
  { id: 'created_date', numeric: true, disablePadding: false, label: 'REPORTED_AT', sort: true },
  { id: "subscribed_at", numeric: true, disablePadding: false, label: "SUBSCRIBED AT", sort: true }
];

//It will create table heading
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            width={headCell.width}
            key={index}
            align={headCell.center ? 'center' : headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  noWrap
                  weight={'medium'}
                  variant={'body2'}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
              :
              <Typography
                noWrap
                weight={'medium'}
                variant={'body2'}
              >
                {headCell.label}
              </Typography>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

//Reported subscribed users component
const SubscribedUserReports = (props) => {
  const classes = useStyles();
  const context = useReportsState();
  var [reports, setBackReportsOfSubscribedUsers] = useState([]);
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created_date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [ReportsOfSubscribedUsersRows, setReportsOfSubscribedUsersRows] = useState([])
  const [searchVal, setSearchVal] = useState('');
  const [fromDate, setFromDate] = useState('')
  const [csvData, setCSVData] = useState([])
  const [toDate, setToDate] = useState('')

  //React hook which calls when page loads
  useEffect(() => {
    getReportsOfSubscribedUsersRequest(context.setReportsOfSubscribedUsers);
  }, []); // eslint-disable-line

  //React hook which calls when context value change
  useEffect(() => {
    setBackReportsOfSubscribedUsers(context.subscribedUsersReports.subscribedUsersReports);
    setReportsOfSubscribedUsersRows(context.subscribedUsersReports.subscribedUsersReports);
    let csvRecords = []
    context.subscribedUsersReports.subscribedUsersReports.data && context.subscribedUsersReports.subscribedUsersReports.data.map((report) => {
      csvRecords.push({
        "Name": report.name,
        "Gender": report.gender,
        "Date of Birth": moment(report.date_of_birth).format("MM-DD-YYYY"),
        "Profile Picture": report.profile_pic,
        "Reason": report.reason,
        "Subscribed At": moment(report.subscribed_at * 1000).format("MM-DD-YYYY hh:mm:ss A"),
        "Reported At": moment(report.created_date * 1000).format("MM-DD-YYYY hh:mm:ss A"),
      })
      return csvRecords;
    })
    setCSVData(csvRecords)
  }, [context]);

  //Function to store Sort by and property to sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  //Function to call when pagination value change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Function to set value of rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Function to search
  const handleSearch = e => {
    let value = e.currentTarget.value.replace(/^\s+/, "")
    setSearchVal(value)
    setPage(0)
    const newArr = ReportsOfSubscribedUsersRows.data.filter(c => {
      return c.name.toLowerCase().includes(value.toLowerCase())
    })
    setBackReportsOfSubscribedUsers({ isLoaded: true, data: newArr })
    let csvRecords = []
    newArr && newArr.map((report) => {
      csvRecords.push({
        "Name": report.name,
        "Gender": report.gender,
        "Date of Birth": moment(report.date_of_birth).format("MM-DD-YYYY"),
        "Profile Picture": report.profile_pic,
        "Reason": report.reason,
        "Subscribed At": moment(report.subscribed_at * 1000).format("MM-DD-YYYY hh:mm:ss A"),
        "Reported At": moment(report.created_date * 1000).format("MM-DD-YYYY hh:mm:ss A"),
      })
      return csvRecords;
    })
    setCSVData(csvRecords)
  }

  //Set start date value
  const changeStartDate = async (e, wantToClear) => {
    if (!wantToClear) {
      setFromDate(moment(new Date(e)).format('MM-DD-YYYY'))
      setToDate('')
    } else {
      setFromDate('')
    }
  };

  //Set end date value
  const changeEndDate = async (e, wantToClear) => {
    if (!wantToClear) {
      setToDate(moment(new Date(e)).format('MM-DD-YYYY'))
      if (fromDate !== '') {
        let data = {
          start_date: moment(fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          end_date: moment(new Date(e)).format('YYYY-MM-DD')
        }
        await getReportsOfSubscribedUsersRequest(context.setReportsOfSubscribedUsers, data);
        setSearchVal('')
        setPage(0)
      }
    } else {
      setToDate('')
      setSearchVal('')
      setPage(0)
      await getReportsOfSubscribedUsersRequest(context.setReportsOfSubscribedUsers);
    }
  }

  //Set start date validation
  const startDateValidation = (currentDate) => {
    var today = moment(new Date());
    return currentDate.isBefore(today);
  }

  //Set end date validation
  const endDateValidation = (currentDate) => {
    var today = moment(new Date());
    // let fromDateVal = moment(fromDate, 'MM-DD-YYYY').format("MM-DD-YYYY")
    // var yesterday = moment(fromDateVal).subtract(1, 'day').format("MM-DD-YYYY");
    const getMonth = moment(fromDate, 'MM-DD-YYYY').get('month') + 1 > 9 ? moment(fromDate, 'MM-DD-YYYY').get('month') + 1 : `0${moment(fromDate, 'MM-DD-YYYY').get('month') + 1}`
    const getYear = moment(fromDate, 'MM-DD-YYYY').get('year')
    const yesterday_date =
      `${getMonth}-${moment(fromDate, 'MM-DD-YYYY').subtract(1, 'day').get('D')}-${getYear}`
    return currentDate.isAfter(new Date(yesterday_date.replace(/-/g, "/"))) && currentDate.isBefore(today);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Widget inheritHeight visibleOverflow>
          <Box
            justifyContent={'space-between'}
            className={classes.filterWrapper}
            alignItems={'center'}
          >
            <Box>
              <div className={classes.datePickerWrapper}>
                <CalendarTodayOutlinedIcon
                  className={classes.calendarIcon}
                />
                <Datetime
                  className={['datePickerExtra']}
                  timeFormat={false}
                  isValidDate={(date) => startDateValidation(date)}
                  onChange={changeStartDate}
                  value={fromDate}
                  dateFormat={'MM-DD-YYYY'}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: 'From',
                    readOnly: true,
                    value: fromDate,
                    style: {
                      border: '1px solid rgb(196 196 196)',
                      borderRadius: '16px',
                      padding: '10.5px 30px 10.5px 40px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      width: '100%',
                    }
                  }}
                />
                <CancelOutlinedIcon
                  className={classes.closeIcon}
                  onClick={(e) => changeStartDate(e, true)}
                />
              </div>
            </Box>
            <Box>
              <div className={classes.datePickerWrapper}>
                <CalendarTodayOutlinedIcon
                  className={classes.calendarIcon}
                />
                <Datetime
                  className={['datePickerExtra']}
                  timeFormat={false}
                  isValidDate={(date) => endDateValidation(date)}
                  onChange={changeEndDate}
                  value={toDate}
                  dateFormat={'MM-DD-YYYY'}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: 'To',
                    readOnly: true,
                    value: toDate,
                    style: {
                      border: '1px solid rgb(196 196 196)',
                      borderRadius: '16px',
                      padding: '10.5px 30px 10.5px 40px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      width: '100%',
                    }
                  }}
                />
                <CancelOutlinedIcon
                  className={classes.closeIcon}
                  onClick={(e) => changeEndDate(e, true)}
                />
              </div>
            </Box>
            <Box>
              <Input
                id="search-field"
                label="Search"
                margin="dense"
                variant="outlined"
                onChange={(e) => handleSearch(e)}
                value={searchVal}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

            </Box>
            <Box>
              {reports.data &&
                <Button variant={'outlined'} color={'primary'} disabled={reports.data && reports.data.length === 0}>
                  <CSVLink data={csvData} filename="subscribed-users.csv" style={{ textDecoration: 'none', display: 'flex', color: reports.data && reports.data.length === 0 ? 'rgba(0, 0, 0, 0.26)' : 'primary', fontSize: '14px' }}>
                    <Box display={'flex'} mr={1}>
                      <DownloadIcon />
                    </Box>
                    Download
                  </CSVLink>
                </Button>
              }
            </Box>
          </Box>
        </Widget>
      </Grid>
      {!context.subscribedUsersReports.isLoaded || !reports.data ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table">

                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={reports.data.length}
                />
                {reports.data.length > 0 ?
                  <TableBody>
                    {stableSort(reports.data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <Typography
                                variant={'body2'}
                              >
                                {(page * rowsPerPage) + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <Typography
                                  variant={'body2'}
                                >
                                  {row.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                display={"flex"}
                                flexWrap={"nowrap"}
                                alignItems={"center"}
                                style={{ height: '50px', width: '50px', margin: '0 auto' }}
                              >
                                <img src={row.profile_pic} className={classes.profilePicture} alt='profile' />
                              </Box>
                            </TableCell>
                            <TableCell>{row.gender == 1 ? "Male" : row.gender == 2 ? "Female" : 'other'}</TableCell>
                            <TableCell>{moment(row.date_of_birth).format("MM-DD-YYYY")}</TableCell>
                            <TableCell>{row.reason}</TableCell>
                            <TableCell>{moment(row.created_date * 1000).format("MM-DD-YYYY hh:mm:ss A")}</TableCell>
                            <TableCell>{moment(row.subscribed_at * 1000).format("MM-DD-YYYY hh:mm:ss A")}</TableCell>
                          </TableRow>

                        );
                      })}
                  </TableBody>
                  :
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={headCells.length}>
                        <Typography
                          style={{ textAlign: 'center', padding: '10px 0px' }}
                          noWrap
                          variant={'h4'}>No Record Found</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={config.defaultRowsPerPage}
              component="div"
              count={reports.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default SubscribedUserReports
