import React from "react";
import { apiCall, displayLog } from "../common/common";

const GenreContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case "GET_GENRE_LIST":
      return {
        isLoaded: true,
        genreList: action.payload,
      };
    case "EDIT_GENRE_STATUS":
      return {
        ...state,
        isLoaded: true
      };
    case "DELETE_GENRE_STATUS":
      return {
        ...state,
        isLoaded: true
      };
    case "EDIT_GENRE":
      return {
        ...state,
        isLoaded: true
      };
    case "CREATE_GENRE":
      return {
        ...state,
        isLoaded: true
      };

    default:
      return {
        ...state
      };
  }
};

const GenreProvider = ({ children }) => {

  const [genreList, setGenreList] = React.useReducer(rootReducer, {
    isLoaded: false,
    genreList: []
  });
  return (
    <GenreContext.Provider value={{ genreList, setGenreList }}>
      {children}
    </GenreContext.Provider>
  );
};

const useGenreState = () => {
  const context = React.useContext(GenreContext);
  return context;
};

//API call to get all genreList
export async function getGenreListRequest(dispatch) {
  let res = await apiCall('GET', 'GET_GENRE_LIST', '/admin/genre', {}, {}, { is_admin: 1 }, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_GENRE_LIST", payload: res.data });
    }
  }
}

//API call to update genre status
export async function updateGenreStatus(dispatch, reqData) {
  let res = await apiCall('PUT', 'EDIT_GENRE_STATUS', '/admin/genreStatus', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "EDIT_GENRE_STATUS" });
    }
  }
}

//API call to delete particular genre
export async function deleteGenre(dispatch, reqData) {
  let res = await apiCall('DELETE', 'DELETE_GENRE_STATUS', '/admin/genre', {}, {}, reqData, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "DELETE_GENRE_STATUS" });
    }
  }
}

//API call to update genre
export async function updateGenre(dispatch, reqData) {
  let res = await apiCall('PUT', 'EDIT_GENRE', '/admin/genre', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "EDIT_GENRE" });
    }
  }
}

//API call to create new genre
export async function createGenre(dispatch, reqData) {
  let res = await apiCall('POST', 'CREATE_GENRE', '/admin/genre', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "CREATE_GENRE" });
    }
  }
}

export { GenreProvider, GenreContext, useGenreState };
