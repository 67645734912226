import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  InputAdornment,
  TextField as Input,
  CircularProgress,
  Switch, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, IconButton, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import Widget from '../../components/Widget/Widget'
import { Button } from '../../components/Wrappers/Wrappers'
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { Typography } from '../../components/Wrappers/Wrappers'

import useStyles from "./styles";

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/themes/default.min.css';
import 'alertifyjs/build/css/alertify.min.css';
import joi from 'joi-browser';

//context
import {
  useGenreState,
  getGenreListRequest,
  updateGenreStatus,
  deleteGenre,
  createGenre,
  updateGenre
} from "../../context/GenreContext";

// Icons
import {
  Add as AddIcon,
  Search as SearchIcon,
  CreateOutlined as CreateIcon,
  DeleteOutlined as DeleteIcon
} from '@material-ui/icons'

import { validate } from '../../common/common';
import config from "../../config";

//Function to sort data
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

//Table headings
const headCells = [
  { numeric: true, disablePadding: false, label: '#', sort: false },
  { id: 'genre_name', numeric: true, disablePadding: false, label: 'NAME', sort: true },
  { id: 'is_active', numeric: true, disablePadding: false, label: 'STATUS', sort: false },
  { id: 'actions', numeric: true, center: true, width: '180px', disablePadding: false, label: 'ACTIONS', sort: false },
]

//It will create table heading
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            width={headCell.width}
            key={index}
            align={headCell.center ? 'center' : headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  noWrap
                  weight={'medium'}
                  variant={'body2'}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
              :
              <Typography
                noWrap
                weight={'medium'}
                variant={'body2'}
              >
                {headCell.label}
              </Typography>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

//Genre component
const Genre = () => {
  const classes = useStyles();
  const context = useGenreState();
  var [genreList, setBackGenreList] = useState(
    context.genreList.genreList
  );
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('genre_name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [genreListRows, setGenreListRows] = useState(context.genreList.genreList)
  const [formValues, setFormValues] = useState({});
  const [wantToEdit, setWantToEdit] = useState(false);
  const [error, setError] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleInputModal, setToggleInputModal] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  //React hook which calls when page loads
  useEffect(() => {
    getGenreListRequest(context.setGenreList);
  }, []); // eslint-disable-line

  //React hook which calls when context value change
  useEffect(() => {
    setBackGenreList(context.genreList.genreList);
    setGenreListRows(context.genreList.genreList);
    if (searchVal !== '') {
      const newArr = context.genreList.genreList.data.filter(c => {
        return c.genre_name.toLowerCase().includes(searchVal.toLowerCase())
      })
      setBackGenreList({ isLoaded: true, data: newArr })
    }
  }, [context]);

  //Function to store Sort by and property to sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  //Function to call when pagination value change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Function to set value of rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Function to search
  const handleSearch = e => {
    let value = e.currentTarget.value.replace(/^\s+/, "")
    setSearchVal(value)
    setPage(0)
    const newArr = genreListRows.data.filter(c => {
      return c.genre_name.toLowerCase().includes(value.toLowerCase())
    })
    setBackGenreList({ isLoaded: true, data: newArr })
  }

  //Function to change the genre status
  const handleChange = async (event, genre_id) => {
    await updateGenreStatus(context.setGenreList, {
      is_active: event.target.checked ? 1 : 0,
      genre_id: genre_id
    });
    await getGenreListRequest(context.setGenreList);
  };

  //function to delete genre
  const deleteGenreHandler = async (e, genre_id) => {
    e.preventDefault();
    alertify.confirm("Are you sure you want to delete?", async (status) => {
      if (status) {
        await deleteGenre(context.setGenreList, {
          genre_id: genre_id
        });
        await getGenreListRequest(context.setGenreList);
      }
    }).setHeader('<em>TrakMeet</em> ').set('labels', { ok: 'OK', cancel: 'CANCEL' });
  }

  //Function to open/close modal
  const manageModal = (wantToEdit, genre) => {
    setError('')
    setErrorField('')
    setWantToEdit(wantToEdit)
    genre ? setFormValues(genre) : setFormValues({ genre_name: '' })
    setToggleInputModal(true)
  }

  //Function which calls on form submit
  const submitGenreHandler = () => {
    let reqData = {
      genre_name: formValues.genre_name,
    }
    validateFormData(reqData);
  }

  //Function to validate form data
  const validateFormData = (body) => {
    let schema = joi.object().keys({
      genre_name: joi.string().trim().required(),
    })
    joi.validate(body, schema, async (err, value) => {
      if (err) {
        if (err.details[0].message !== error || error.details[0].context.key !== errorField) {
          let errorLog = validate(err)
          setError(errorLog.error)
          setErrorField(errorLog.errorField)
        }
      }
      else {
        setError('')
        setErrorField('')
        setIsLoading(true)
        let reqData = {}
        if (wantToEdit) {
          reqData = {
            genre_name: body.genre_name,
            genre_id: formValues.genre_id
          }
          await updateGenre(context.setGenreList, reqData)
        } else {
          reqData = {
            genre_name: body.genre_name,
          }
          await createGenre(context.setGenreList, reqData)
        }
        await getGenreListRequest(context.setGenreList);
        setToggleInputModal(false)
        setIsLoading(false)
      }
    })
  }

  return (
    <Grid container spacing={3}>
      <span className={classes.mainPageTitle}>Genre Management</span>
      <Grid item xs={12}>
        <Widget inheritHeight>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Box style={{ margin: "0 12px 0 0" }}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => manageModal(false)}
              >
                <Box mr={1} display={'flex'}>
                  <AddIcon />
                </Box>
                Add
              </Button>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-end'}
            >
              <Input
                id="search-field"
                label="Search"
                margin="dense"
                variant="outlined"
                onChange={(e) => handleSearch(e)}
                value={searchVal}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Widget>
      </Grid>
      {!context.genreList.isLoaded || !genreList.data ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table">

                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={genreList.data.length}
                />
                {genreList.data.length > 0 ?
                  <TableBody>
                    {stableSort(genreList.data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <Typography
                                variant={'body2'}
                              >
                                {(page * rowsPerPage) + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <Typography
                                  variant={'body2'}
                                >
                                  {row.genre_name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant={'body2'}
                              >
                                <Switch
                                  checked={row.is_active === 1 ? true : false}
                                  onChange={(e) => handleChange(e, row.genre_id)}
                                  color="primary"
                                  name="checkedB"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Box display={'flex'} justifyContent={'center'}>
                                <IconButton
                                  color={'primary'}
                                  onClick={() => manageModal(true, row)}
                                >
                                  <CreateIcon />
                                </IconButton>
                                <IconButton
                                  color={'primary'}
                                  onClick={(e) => deleteGenreHandler(e, row.genre_id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>

                        );
                      })}
                  </TableBody>
                  :
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={headCells.length}>
                        <Typography
                          style={{ textAlign: 'center', padding: '10px 0px' }}
                          noWrap
                          variant={'h4'}>No Record Found</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={config.defaultRowsPerPage}
              component="div"
              count={genreList.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
      <Dialog
        open={toggleInputModal}
        onClose={() => setToggleInputModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{wantToEdit ? 'Edit' : 'Add'} Genre</DialogTitle>
        <DialogContent>
          <Input

            label='Genre'
            placeholder={"Genre"}
            margin="normal"
            variant="outlined"
            onChange={e => setFormValues({ ...formValues, genre_name: e.target.value.replace(/^\s+/, "") })}
            InputProps={{
              classes: {
                underline: classes.InputUnderline,
                input: classes.Input
              }
            }}
            value={formValues.genre_name}
            fullWidth
          />
          {errorField === 'genre_name' && <ErrorMessage error={error} />}
        </DialogContent>
        <DialogActions style={{ padding: "10px 24px 20px" }}>
          <Button
            variant={"outlined"}
            color="primary"
            onClick={() => setToggleInputModal(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            onClick={submitGenreHandler}
            disabled={isLoading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default Genre
