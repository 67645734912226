import React from "react";
import { apiCall, displayLog } from "../common/common";

const SubscriptionsContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case "GET_SUBSCRIPTION_PLANS":
      return {
        isLoaded: true,
        plans: action.payload,
      };
    case "EDIT_SUBSCRIPTION_PLAN_PRICE":
      return {
        ...state,
        isLoaded: true
      };
    case "GET_SUBSCRIBERS":
      return {
        isLoaded: true,
        subscribers: action.payload,
      };
    default:
      return {
        ...state
      };
  }
};

const SubscriptionsProvider = ({ children }) => {

  const [plans, setSubscriptionPlans] = React.useReducer(rootReducer, {
    isLoaded: false,
    plans: []
  });
  const [subscribers, setSubscribersList] = React.useReducer(rootReducer, {
    isLoaded: false,
    subscribers: []
  });
  return (
    <SubscriptionsContext.Provider value={{ plans, setSubscriptionPlans, subscribers, setSubscribersList }}>
      {children}
    </SubscriptionsContext.Provider>
  );
};

const useSubscriptionsState = () => {
  const context = React.useContext(SubscriptionsContext);
  return context;
};

//API call to get all subscription plans
export async function getSubscriptionPlansRequest(dispatch) {
  let res = await apiCall('GET', 'GET_SUBSCRIPTION_PLANS', '/admin/plans', {}, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_SUBSCRIPTION_PLANS", payload: res.data });
    }
  }
}

//API call to edit particular subscription plan
export async function editSubscriptionPlanPriceRequest(dispatch, reqData) {
  let res = await apiCall('PUT', 'EDIT_SUBSCRIPTION_PLAN_PRICE', '/admin/planPrice', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "EDIT_SUBSCRIPTION_PLAN_PRICE" });
    }
  }
}

//API call to get all subscribers
export async function getSubscribersRequest(dispatch) {
  let res = await apiCall('GET', 'GET_SUBSCRIBERS', '/admin/subscribers', {}, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_SUBSCRIBERS", payload: res.data });
    }
  }
}

export { SubscriptionsProvider, SubscriptionsContext, useSubscriptionsState };
