import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
    Grid,
    Box,
    TextField as Input,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button,
    Menu,
    OutlinedInput,
    ListItemText,
    Slider,
    withStyles,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';

import logo from "../../images/darkMusic.svg";
import Widget from '../../components/Widget/Widget'
import { Typography } from '../../components/Wrappers/Wrappers'
import useStyles from "./styles";
import alertify from 'alertifyjs';
import { Add, Close } from '@material-ui/icons';
import { apiCall } from '../../common/common';

//User details component
const EditUser = props => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [buttonloading, setButtonLoading] = useState(false);

    const [userId, setUserId] = useState(0);
    const [GENRELIST, setGENRELIST] = useState([])
    const [genre_data, setGenre_data] = useState([]);
    const [TALENTLIST, setTALENTLIST] = useState([])
    const [talent_data, setTalent_data] = useState([]);

    // length state (based on user subscribed or not)
    const [imageLength, setImageLength] = useState(0);
    const [audioLength, setAudioLength] = useState(0);

    const [imgsSrc, setImgsSrc] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [validate, setValidate] = useState(false);

    const [modalType, setModalType] = useState('add');
    const [audioArray, setAudioArray] = useState([]);
    const [audiomodal, setAudioModal] = useState(false);
    const [editModalData, setEditModalData] = useState([]);
    const [modalData, setModalData] = useState({
        name: "",
        image: "",
        audio: "",
        image_url: "",
        audio_url: ""
    });
    const [stepData_1, setStepData_1] = useState({
        is_admin_user: 1,
        email: "",
        date_of_birth: "",
        gender: "",
        pronouns: "",
        is_pronouns: "",
    });
    const [stepData_2, setStepData_2] = useState({
        first_name: "",
        last_name: "",
        user_name: "",
        phone_number: "",
        state: "",
        city: "",
        zip_code: "",
        about_me: "",
    });

    const currentYear = new Date().getFullYear();
    const year = stepData_1.date_of_birth.length > 0 && stepData_1.date_of_birth.split("-")[0];
    const age = currentYear - year;

    useEffect(() => {
        if (props.location.state && props.location.state.user_id) {
            setImageLength(props.location.state.is_subscribe == 1 ? 10 : 3)
            setAudioLength(props.location.state.is_subscribe == 1 ? 10 : 1)
            getUserData();
            getGenreData();
            getTalentData();
        }
    }, []);

    const getGenreData = async () => {
        const reqData = {
            'is_admin_user': 1
        }
        const response = await apiCall('GET', '', '/genre', {}, {}, reqData, {});
        if (response && response.status == 200) {
            setGENRELIST(response.data.data);
        }

    };
    const getTalentData = async () => {
        const reqData = {
            'is_admin_user': 1
        }
        const response = await apiCall('GET', '', '/talent_type', {}, {}, reqData, {});
        if (response && response.status == 200) {
            setTALENTLIST(response.data.data);
        }
    };
    const getUserData = async () => {
        setLoading(true);
        const reqData = {
            is_admin_user: 1,
            user_id: props.location.state.user_id,
        }
        const response = await apiCall('GET', '', '/profile', {}, {}, reqData, {});
        if (response && response.status == 200) {
            const user_Data = response.data.data;
            const talent_arr = user_Data.talentTypes.map(talentType => talentType.talent_type_name);
            const genre_arr = user_Data.genres.map(genre => genre.genre_name);
            setUserId(user_Data.user_id);
            setStepData_1({
                is_admin_user: 1,
                email: user_Data.email ? user_Data.email : "",
                date_of_birth: user_Data.date_of_birth ? user_Data.date_of_birth : "",
                gender: user_Data.gender ? user_Data.gender : "",
                pronouns: user_Data.pronouns == '2' ? user_Data.pronouns : user_Data.gender == "3" ? "2" : "1",
                is_pronouns: user_Data.is_pronouns ? user_Data.is_pronouns : "",
            });
            setStepData_2({
                first_name: user_Data.name ? user_Data.name : "",
                last_name: user_Data.last_name ? user_Data.last_name : "",
                user_name: user_Data.user_name ? user_Data.user_name : "",
                phone_number: user_Data.mobile_number ? user_Data.mobile_number : "",
                state: user_Data.state ? user_Data.state : "",
                city: user_Data.city ? user_Data.city : "",
                zip_code: user_Data.zipcode ? user_Data.zipcode : "",
                about_me: user_Data.description ? user_Data.description : "",
            });
            setImageArray(user_Data.pictures ? user_Data.pictures : []);
            setAudioArray(user_Data.audios ? user_Data.audios : []);
            setGenre_data(genre_arr);
            setTalent_data(talent_arr)
            setLoading(false);
        }
    };
    // CHANGE HANDLERS
    const handleChangeStep_1 = (e) => {
        setValidate(false);
        const { value, name } = e.target;
        if (name == 'gender') {
            setStepData_1((prev) => ({
                ...prev,
                [name]: value,
                is_pronouns: "",
                pronouns: "",
            }))
        }
        else {
            setStepData_1((prev) => ({
                ...prev,
                [name]: value
            }))
        }
    };
    const handleChangeStep_2 = (e) => {
        setValidate(false);
        const { value, name } = e.target;
        let regex = /^[0-9]+$/;
        const alpha = value.length == 0 ? true : regex.test(value);
        if ((name == 'zip_code' || name == 'phone_number') && !alpha) return;
        setStepData_2((prev) => ({
            ...prev,
            [name]: value
        }))
    };
    const handlePronounceChange = (event) => {
        setStepData_1((prev) => ({
            ...prev,
            "pronouns": event?.target?.checked ? "2" : "1"
        }));
        setValidate(false);
    };

    // IMAGE
    const onImageSelect = (e) => {
        setValidate(false);
        for (const file of e.target.files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImgsSrc((imgs) => [...imgs, reader.result]);
                uploadImage(file)
            };
            reader.onerror = () => {
                console.log(reader.error);
            };
        }
    };
    const onImageDelete = async (item, index) => {
        const reqData = {
            is_admin_user: 1,
            user_id: item.user_id,
            profile_photo_id: item.profile_photo_id
        }
        const response = await apiCall('DELETE', '', `/profilePicture`, {}, {}, reqData, {});
        if (response && response.status == 200) {
            imageUpdatePosition(imageArray.filter((items, id) => items.profile_photo_id !== item.profile_photo_id))
        }
    };
    const uploadImage = async (imagefile) => {
        setLoading(true);
        const position_last = imageArray[imageArray.length - 1].position
        const formData = new FormData();
        formData.append(`profile_pic`, imagefile);
        formData.append(`position`, position_last + 1);
        formData.append(`user_id`, userId);
        formData.append(`is_admin_user`, 1);
        const response = await apiCall('POST', '', '/profilePicture', formData, {}, {}, {});
        if (response && response.status == 200) {
            getUserData('3')
            setLoading(false);
            getUserData();
            // setImageArray((prev) => ([
            //     ...prev,
            //     response.data.data.profile_pic
            // ]))
        }
    };
    const imageUpdatePosition = async (array) => {
        const temp_array = array.map((obj, index) => {
            return {
                ...obj,
                'position': index + 1
            }
        });
        let reqArray = [];
        temp_array.map((obj) => {
            let id = obj.profile_photo_id
            let imgPosition = obj.position
            reqArray.push({
                id: id,
                position: imgPosition
            })
        })
        let params = {
            'position': reqArray,
            'user_id': userId,
            'is_admin_user': 1
        };
        const response = await apiCall('POST', "", "/profilePosition", params, {}, {}, {});
        if (response && response.status == 200) {
            await getUserData('3');
        }
    };

    // AUDIO
    const removeAudioFormat = (string) => {
        let new_string = string;
        const audioFormatRegex = /\.(mp3|wav|ogg|flac|aac|m4a)$/i;
        if (audioFormatRegex.test(string)) {
            new_string = string.replace(audioFormatRegex, "");
        }
        return new_string;
    };
    const addAudioFormat = (string) => {
        let new_string;
        const audioFormatRegex = /\.(mp3|wav|ogg|flac|aac|m4a)$/i;
        if (!audioFormatRegex.test(string)) {
            new_string = string + ".mp3";
        }
        return new_string;
    }
    const audioModalOpen = (isEdit, item) => {
        setAudioModal(true);
        setModalType(isEdit ? 'edit' : 'add');
        setEditModalData(isEdit ? item : false);
        isEdit && setModalData({
            name: item.audio_name,
            image: {},
            audio: item.audio,
            image_url: item.image,
            audio_url: item.audio
        })
    };
    const audioModalClose = () => {
        setAudioModal(false);
        setModalData({
            name: "",
            image: "",
            audio: "",
            image_url: "",
            audio_url: ""
        });
    };
    const onAudioDelete = async (item, index) => {
        const reqData = {
            is_admin_user: 1,
            user_id: item.user_id,
            audio_id: item.audio_id
        }
        const response = await apiCall('DELETE', "", '/profileAudio', {}, {}, reqData, {});
        const filteredArray_file = audioArray.filter((item, id) => id !== index);
        setAudioArray(filteredArray_file);
    };
    const handleEditAudioAPI = async () => {
        setButtonLoading(true);
        let formData = new FormData();
        formData.append(`is_admin_user`, 1);
        formData.append('type', 3);
        formData.append('user_id', userId);
        formData.append('audio_id', editModalData.audio_id);
        formData.append('new_audio_name', addAudioFormat(modalData.name));
        formData.append('old_audio_name', addAudioFormat(editModalData.audio_name));
        formData.append('old_audio_url', editModalData.audio);
        if (modalData?.image?.name) {
            formData.append('image', modalData.image);
        }
        const response = await apiCall('POST', "", '/renameAudioFile', formData, {}, {}, {});
        if (response && response.status == 200) {
            getUserData('3');
            audioModalClose();
            setButtonLoading(false);
        }
    };
    const handleAddAudioAPI = async () => {
        setButtonLoading(true);
        const audio = new Audio();
        audio.src = URL.createObjectURL(modalData.audio);
        let audioDuraiton;
        audio.onloadedmetadata = async () => {
            if (audio.duration > 300) {
                setButtonLoading(false);
                alertify.set('notifier', 'position', 'top-right');
                alertify.error('Please upload audio upto 5 minutes');
                return;
            }
            else {
                audioDuraiton = Math.floor(audio.duration * 1000);
                let formData = new FormData();
                formData.append('user_id', userId);
                formData.append('duration', audioDuraiton);
                formData.append('position', audioArray.length + 1);
                formData.append('audio', modalData.audio);
                formData.append('audio_name', `${modalData.name.length > 0 ? addAudioFormat(modalData.name) : modalData?.audio?.name}`);
                formData.append(`is_admin_user`, 1);
                if (modalData.image?.name) {
                    formData.append('image', modalData.image);
                }
                const response = await apiCall('POST', "", '/profileAudio', formData, {}, {}, {});
                setAudioArray((prev) => ([
                    ...prev,
                    response.data.data
                ]));
                audioModalClose();
                getUserData('3');
                setButtonLoading(false);
            }

        };
    };

    //GENRE & TALENT for STEP 2
    const handleChange_genre = (event) => {
        setValidate(false);
        const {
            target: { value },
        } = event;
        const allGenreInPrev = genre_data.includes('All Genres');
        const allGenreInNew = value.includes('All Genres');

        if (allGenreInPrev) {
            if (allGenreInNew) {
                if (value.length == 9) {
                    const tempArray = value.filter((item) => item != 'All Genres')
                    setGenre_data(tempArray)
                } else {
                    setGenre_data(
                        typeof value === 'string' ? value.split(',') : value,
                    );
                }
            }
            else {
                setGenre_data([]);
            }
        } else {
            if (allGenreInNew) {
                const tempArray = GENRELIST.map((item) => {
                    return item.genre_name
                })
                setGenre_data(tempArray)
            }
            else {
                if (value.length == 9) {
                    const tempArray = GENRELIST.map((item) => {
                        return item.genre_name
                    })
                    setGenre_data(tempArray)
                } else {
                    setGenre_data(
                        typeof value === 'string' ? value.split(',') : value,
                    );
                }
            }
        }
    };
    const handleChange_talent = (event) => {
        setValidate(false);
        const {
            target: { value },
        } = event;
        const allTalentInPrev = talent_data.includes('All Talent');
        const allTalentInNew = value.includes('All Talent');
        if (allTalentInPrev) {
            if (allTalentInNew) {
                if (value.length == 7) {
                    const tempArray = value.filter((item) => item != 'All Talent')
                    setTalent_data(tempArray)
                } else {
                    setTalent_data(
                        typeof value === 'string' ? value.split(',') : value,
                    );
                }
            }
            else {
                setTalent_data([]);
            }
        } else {
            if (allTalentInNew) {
                const tempArray = TALENTLIST.map((item) => {
                    return item.talent_type_name
                })
                setTalent_data(tempArray)
            }
            else {
                if (value.length == 7) {
                    const tempArray = TALENTLIST.map((item) => {
                        return item.talent_type_name
                    })
                    setTalent_data(tempArray)
                } else {
                    setTalent_data(
                        typeof value === 'string' ? value.split(',') : value,
                    );
                }
            }
        }
    };

    // BUTTON ONCLICKS
    const validation_step_2 = () => {
        setValidate(true);
        if (stepData_1.date_of_birth.length == 0) return false;
        else if (age <= 18) return false;
        else if (stepData_2.first_name.trim().length == 0) return false;
        else if (stepData_2.user_name.trim().length == 0) return false;
        else if (stepData_2.phone_number.trim().length > 0 && stepData_2.phone_number.trim().length > 15) return false;
        else if (stepData_2.phone_number.trim().length > 0 && stepData_2.phone_number.trim().length < 7) return false;
        else if (stepData_2.state.trim().length == 0) return false;
        else if (stepData_2.city.trim().length == 0) return false;
        else if (stepData_2.zip_code.trim().length == 0) return false;
        else if (stepData_2.zip_code.trim().length > 10) return false;
        else if (stepData_2.about_me.trim().length == 0 || stepData_2.about_me.trim().length < 100) return false
        else if (genre_data.length == 0) return false;
        else if (talent_data.length == 0) return false;
        else return true;
    };

    const profileSetupUser = async () => {
        if (validation_step_2()) {
            setButtonLoading(true);
            const selectedGenreIds = genre_data.map(selectedName => {
                const genre = GENRELIST.find(genre => genre.genre_name === selectedName);
                return genre ? genre.genre_id : null;
            });

            const selectedTalentIds = talent_data.map(selectedName => {
                const talentType = TALENTLIST.find(talent => talent.talent_type_name === selectedName);
                return talentType ? talentType.talent_type_id : null;
            });

            let reqData = {
                is_admin_user: 1,
                email: stepData_1.email,
                user_id: userId,
                user_name: stepData_2.user_name,
                name: stepData_2.first_name,
                state: stepData_2.state,
                city: stepData_2.city,
                gender: stepData_1.gender,
                date_of_birth: stepData_1.date_of_birth,
                zipcode: stepData_2.zip_code,
                genre_id: selectedGenreIds,
                talent_type_id: selectedTalentIds,
                description: stepData_2.about_me
            };
            const data1 = {
                last_name: stepData_2.last_name,
            }
            const data2 = {
                mobile_number: stepData_2.phone_number
            }
            const data3 = {
                is_pronouns: parseInt(stepData_1.is_pronouns),
                pronouns: stepData_1.pronouns.toString()
            }
            if (stepData_2?.last_name.length > 0) {
                reqData = { ...reqData, ...data1 }
            }
            if (stepData_2?.phone_number.length > 0) {
                reqData = { ...reqData, ...data2 }
            }
            if (stepData_1?.gender == '3' && stepData_1.pronouns == "2") {
                reqData = { ...reqData, ...data3 }
            }
            const response = await apiCall('POST', '', '/profile', reqData);
            if (response && response.status == 200 && response?.data?.code != 0) {
                setUserId(response.data.data.user_id);
                setButtonLoading(false);
                setValidate(false);
                alertify.set('notifier', 'position', 'top-right');
                alertify.success(response.data.data.message || response.data.message);
                getUserData();
            }
            else {
                alertify.set('notifier', 'position', 'top-right');
                alertify.error(response.data.data.message || response.data.message || 'Error while updating data');
            }
        }
    };
    return (
        <Grid container spacing={3}>
            <span className={classes.mainPageTitle}>Edit User</span>
            {
                loading ?
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"100vw"}
                        height={"calc(100vh - 200px)"}
                    >
                        <CircularProgress size={50} />
                    </Box>
                    :
                    <Widget className={classes.boxContainer}>

                        <h2>User Details</h2>

                        {/* email & DOB */}
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start" }}>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    label="Email"
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    className={classes.inputBox}
                                    name='email'
                                    value={stepData_1.email}
                                    onChange={(e) => handleChangeStep_1(e)}
                                />
                                {validate && stepData_1.email.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Email field is required</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    label="Date of birth"
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        },
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    type='date'
                                    className={classes.inputBox}
                                    name='date_of_birth'
                                    value={stepData_1.date_of_birth}
                                    onChange={(e) => handleChangeStep_1(e)}
                                />
                                {validate && stepData_1.date_of_birth.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Birthdate field is required</p>
                                    : validate && age <= 18 ?
                                        <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Birthdate is not valid</p>
                                        : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                        </div>

                        {/* Gender & Pronouns */}
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "start",
                        }}>
                            <div style={{ width: "50%", height: "150px" }}>
                                <Typography
                                    variant={'subtitle1'}
                                    color={'black'}
                                    className={classes.formLabels}
                                    style={{ marginTop: '0px' }}
                                >
                                    Gender
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={stepData_1.gender}
                                    onChange={(e) => handleChangeStep_1(e)}
                                    name="gender"
                                >
                                    <FormControlLabel value={"1"} control={<Radio />} label="Male" />
                                    <FormControlLabel value={"2"} control={<Radio />} label="Female" />
                                    <FormControlLabel value={"3"} control={<Radio />} label="Other" />
                                </RadioGroup>
                            </div>
                            {stepData_1.gender == '3' && <div style={{ width: "50%", height: "150px" }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>
                                    <Typography
                                        variant={'subtitle1'}
                                        color={'black'}
                                        className={classes.formLabels}
                                        style={{ marginTop: '0px' }}
                                    >
                                        Pronouns
                                    </Typography>
                                    <Switch
                                        onChange={handlePronounceChange}
                                        value={stepData_1.pronouns == '2'}
                                        color='secondary'
                                        defaultChecked={stepData_1.pronouns == '2'}
                                    />
                                </div>
                                {stepData_1.pronouns == '2' &&
                                    <div>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={stepData_1.is_pronouns.toString()}
                                            onChange={(e) => handleChangeStep_1(e)}
                                            name="is_pronouns"
                                        >
                                            <FormControlLabel value={"1"} control={<Radio />} label="he/him/his" />
                                            <FormControlLabel value={"2"} control={<Radio />} label="she/her/hers" />
                                            <FormControlLabel value={"3"} control={<Radio />} label="they/them/theirs" />
                                        </RadioGroup>
                                        {validate && stepData_1 == 0 ?
                                            <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Please select pronounce</p>
                                            : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                        }
                                    </div>
                                }
                            </div>}
                        </div>

                        {/* name */}
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start", marginTop: '20px' }}>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='First Name'
                                    className={classes.inputBox}
                                    value={stepData_2.first_name}
                                    name='first_name'
                                    onChange={(e) => handleChangeStep_2(e)}
                                    disabled={userId.length == 0 || userId == 0}
                                />
                                {validate && stepData_2.first_name.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*First name is required</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='Last Name'
                                    className={classes.inputBox}
                                    value={stepData_2.last_name}
                                    name='last_name'
                                    onChange={(e) => handleChangeStep_2(e)}
                                    disabled={userId.length == 0 || userId == 0}
                                />
                            </div>
                        </div>

                        {/* username & phone number */}
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start" }}>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='Username'
                                    className={classes.inputBox}
                                    value={stepData_2.user_name}
                                    name='user_name'
                                    onChange={(e) => handleChangeStep_2(e)}
                                    disabled={userId.length == 0 || userId == 0}
                                />
                                {validate && stepData_2.user_name.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Username is required</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='PhoneNumber(optional)'
                                    className={classes.inputBox}
                                    value={stepData_2.phone_number}
                                    name='phone_number'
                                    onChange={(e) => handleChangeStep_2(e)}
                                    disabled={userId.length == 0 || userId == 0}
                                />
                                {validate && stepData_2.phone_number.trim().length > 0 && (stepData_2.phone_number.trim().length > 15 || stepData_2.phone_number.trim().length < 7) ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Phone number is not valid</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                        </div>

                        {/* address */}
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start" }}>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='State'
                                    className={classes.inputBox}
                                    value={stepData_2.state}
                                    name='state'
                                    onChange={(e) => handleChangeStep_2(e)}
                                    disabled={userId.length == 0 || userId == 0}
                                />
                                {validate && stepData_2.state.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}> *State is required</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='City'
                                    className={classes.inputBox}
                                    value={stepData_2.city}
                                    name='city'
                                    onChange={(e) => handleChangeStep_2(e)}
                                    disabled={userId.length == 0 || userId == 0}
                                />
                                {validate && stepData_2.city.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*City is required</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                            <div style={{ marginTop: '10px', width: "100%", marginRight: "1%" }}>
                                <Input
                                    style={{ width: "98%", margin: 0 }}
                                    type='text'
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: classes.InputH
                                        }
                                    }}
                                    label='Zip Code'
                                    className={classes.inputBox}
                                    value={stepData_2.zip_code}
                                    name='zip_code'
                                    disabled={userId.length == 0 || userId == 0}
                                    onChange={(e) => handleChangeStep_2(e)}
                                />
                                {validate && stepData_2.zip_code.trim().length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Zipcode is required</p>
                                    : validate && stepData_2.zip_code.trim().length > 10 ?
                                        <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Zipcode is not valid</p>
                                        : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </div>
                        </div>

                        {/* about me */}
                        <div>
                            <Input
                                style={{ marginTop: '10px', width: "98%", marginRight: "2%" }}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        input: classes.InputH,
                                    }
                                }}
                                label='About me'
                                className={classes.inputBox}
                                value={stepData_2.about_me}
                                name='about_me'
                                multiline
                                onChange={(e) => handleChangeStep_2(e)}
                                disabled={userId.length == 0 || userId == 0}
                            />
                            {validate && (stepData_2.about_me.trim().length == 0) ?
                                <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}> *Description is required</p>
                                : validate && stepData_2.about_me.trim().length < 100 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}> *Please describe yourself in minimum of 100 characters</p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                            }
                        </div>

                        {/* genre & talent type */}
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start" }}>
                            <FormControl sx={{ m: 1, }} style={{ width: "100%", marginRight: "2%" }}>
                                <InputLabel style={{ position: 'relative', left: "15px", top: "10px" }} id="demo-multiple-checkbox-label">Genre</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={genre_data}
                                    onChange={handleChange_genre}
                                    // variant='outlined'
                                    input={<OutlinedInput label="Genre" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    disabled={userId.length == 0 || userId == 0}
                                >
                                    {GENRELIST.map((item, index) => (
                                        <MenuItem key={index} value={item.genre_name}>
                                            <Checkbox checked={genre_data.indexOf(item.genre_name) > -1} />
                                            <ListItemText primary={item.genre_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {validate && genre_data.length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Please select genre </p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </FormControl>
                            <FormControl sx={{ m: 1, }} style={{ width: "100%", marginRight: "2%" }}>
                                <InputLabel style={{ position: 'relative', left: "15px", top: "10px" }} id="demo-multiple-checkbox-label"> Talent type</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={talent_data}
                                    onChange={handleChange_talent}
                                    input={<OutlinedInput label="Talent type" style={{ marginLeft: "20px" }} />}
                                    style={{
                                        width: "100%"
                                    }}
                                    renderValue={(selected) => selected.join(', ')}
                                    disabled={userId.length == 0 || userId == 0}
                                >
                                    {TALENTLIST.map((item, index) => (
                                        <MenuItem key={index} style={{}} value={item.talent_type_name}>
                                            <Checkbox checked={talent_data.indexOf(item.talent_type_name) > -1} />
                                            <ListItemText primary={item.talent_type_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {validate && talent_data.length == 0 ?
                                    <p style={{ margin: 0, padding: 0, color: "red", fontSize: "12px", height: "15px" }}>*Please select talent type </p>
                                    : <p style={{ margin: 0, padding: 0, height: "15px" }} />
                                }
                            </FormControl>
                        </div>

                        {/* save button */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                marginRight: "2%"
                            }}>
                            <Button
                                variant={"contained"}
                                color="primary"
                                style={{ marginTop: "20px" }}
                                onClick={() => profileSetupUser()}
                            // disabled={userId.length == 0 || userId == 0}
                            >
                                {(buttonloading) && <CircularProgress color='#aba0eb' size={20} style={{ marginRight: "10px" }} />}
                                Update
                            </Button>
                        </div>

                        {/* profile photo */}
                        <h2 style={{ marginTop: "30px" }}>Profile Image</h2>
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "flex-start",
                                flexWrap: "wrap"
                            }}>
                                {imageArray.length > 0 ? imageArray.map((item, index) => (
                                    <div className="">
                                        <img
                                            src={item.profile_pic}
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top',
                                                height: '9rem',
                                                width: '9rem',
                                                marginTop: '1.25rem',
                                                marginRight: '1.25rem',
                                                borderRadius: '9999px',
                                            }} />
                                        {imageArray.length > 1 && <button
                                            style={{ border: "0px", background: "transparent", cursor: "pointer" }}
                                            onClick={() => onImageDelete(item, index)}>
                                            <Close
                                                style={{
                                                    position: 'relative',
                                                    top: '-110px',
                                                    right: '55px',
                                                    backgroundColor: '#d9d9d9',
                                                    borderRadius: '100px'
                                                }}
                                            />
                                        </button>}
                                    </div>
                                ))
                                    :
                                    <div className="">
                                        <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO0qWmbuXjUkWU7Ww3OV3z5RfF8TLGsipaaw&usqp=CAU'} style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            height: '9rem',
                                            width: '9rem',
                                            marginTop: '1.25rem',
                                            marginRight: '1.25rem',
                                            borderRadius: '9999px',
                                        }} />
                                    </div>}
                            </div>
                            <label htmlFor="add-photo">
                                <div
                                    style={{
                                        cursor: imageArray.length >= imageLength
                                            ? "not-allowed"
                                            : 'pointer',
                                        backgroundColor: imageArray.length >= imageLength
                                            ? "#d4d4d4"
                                            : "#7b67ed",
                                        border: imageArray.length >= imageLength
                                            ? '1px solid #d4d4d4'
                                            : '1px solid #7b67ed',
                                        color: imageArray.length >= imageLength
                                            ? "black" : "white",
                                        textTransform: "uppercase"
                                    }}
                                    className={classes.ImageAddButton}
                                >
                                    <Add />
                                    Add Photos
                                </div>
                            </label>
                            <input
                                type={(imageArray.length >= imageLength) ? "text" : "file"}
                                id={"add-photo"}
                                style={{ display: "none", cursor: 'pointer' }}
                                accept='image/png, image/jpeg, image/jpg'
                                onChange={(e) => onImageSelect(e)}
                                // disabled={imageArray.length >= imageLength ? true : false}
                                onClick={() => {
                                    if (imageArray.length >= imageLength) {
                                        alertify.set('notifier', 'position', 'top-right')
                                        alertify.error("You have reached your plan limit for photo uploads. Please upgrade your plan for additional photo upload capacity")
                                    }
                                }}
                            />
                        </>
                        <h2 style={{ marginTop: "50px" }}>Profile Audio</h2>
                        {/* audio */}
                        <div style={{ marginTop: "20px", }}>
                            <div
                                style={{
                                    cursor: audioArray.length >= audioLength
                                        ? "not-allowed"
                                        : 'pointer',
                                    backgroundColor: audioArray.length >= audioLength
                                        ? "#d4d4d4"
                                        : "#7b67ed",
                                    border: audioArray.length >= audioLength
                                        ? '1px solid #d4d4d4'
                                        : '1px solid #7b67ed',
                                    color: audioArray.length >= audioLength
                                        ? "black" : "white",
                                    textTransform: "uppercase"
                                }}
                                onClick={() => {
                                    if (audioArray.length >= audioLength) {
                                        alertify.set('notifier', 'position', 'top-right')
                                        alertify.error("You have reached your plan limit for audio uploads. Please upgrade your plan for additional audio upload capacity")
                                    }
                                    else audioModalOpen(false)
                                }}
                                className={classes.ImageAddButton}
                            >
                                <Add />
                                Add Audio
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "flex-start",
                                flexWrap: "wrap"
                            }}>
                                {audioArray.length > 0 ? audioArray.map((item, index) => (
                                    <div className="" style={{ marginRight: "20px" }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}>
                                            <>
                                                <Input
                                                    style={{ marginTop: '20px', marginBottom: "10px", width: "100%" }}
                                                    margin="dense"
                                                    variant="outlined"
                                                    label='Audio Name'
                                                    className={classes.inputBox}
                                                    value={removeAudioFormat(item.audio_name)}
                                                    name='audio_name'
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.InputH,
                                                        }
                                                    }}
                                                />
                                                {item.image ?
                                                    <img
                                                        src={item.image ? item.image : logo}
                                                        style={{
                                                            objectFit: 'cover',
                                                            objectPosition: 'top',
                                                            height: '150px',
                                                            width: '150px',
                                                            alignSelf: "center"
                                                        }}
                                                    /> :
                                                    <div style={{
                                                        marginTop: "10px",
                                                        height: '150px',
                                                        width: '150px',
                                                        backgroundColor: "#d6d0f7",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <img
                                                            src={logo}
                                                            style={{
                                                                objectFit: 'cover',
                                                                objectPosition: 'top',
                                                                height: '55px',
                                                                width: '50px',
                                                                backgroundColor: "#d6d0f7"
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <audio
                                                    style={{ marginTop: "10px" }}
                                                    controls
                                                    src={item.audio}>
                                                </audio>
                                            </>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                                alignItems: "center",
                                                width: "100%",
                                                flexDirection: "row",
                                            }}>
                                                {audioArray.length > 1 && <Button
                                                    variant={"contained"}
                                                    style={{ marginTop: "20px", backgroundColor: "#ff6e6e", color: "white" }}
                                                    onClick={() => onAudioDelete(item, index)}
                                                >
                                                    Delete
                                                </Button>}
                                                <Button
                                                    variant={"contained"}
                                                    style={{ marginTop: "20px", backgroundColor: "#669eff", color: "white" }}
                                                    onClick={() => audioModalOpen(true, item)}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                ))
                                    :
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}>
                                        <Input
                                            style={{ marginTop: '20px', marginBottom: "10px", width: "100%" }}
                                            margin="dense"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    input: classes.InputH,
                                                }
                                            }}
                                            label='Audio Name'
                                            className={classes.inputBox}
                                            value={""}
                                            name='audio_name'
                                            onChange={{}}
                                            disabled={true}
                                        />
                                        <div style={{
                                            marginTop: "10px",
                                            height: '150px',
                                            width: '150px',
                                            backgroundColor: "#d6d0f7",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <img
                                                src={logo}
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'top',
                                                    height: '55px',
                                                    width: '50px',
                                                    backgroundColor: "#d6d0f7"
                                                }}
                                            />
                                        </div>
                                        <audio
                                            style={{ marginTop: "10px" }}
                                            controls
                                            src={""}>
                                        </audio>
                                    </div>
                                }
                            </div>
                        </div>

                    </Widget>
            }
            <Dialog
                open={audiomodal}
                onClose={() => audioModalClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{modalType == 'add' ? "Add Audio" : "Edit Audio"}</DialogTitle>
                <DialogContent>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        flexDirection: "column"
                    }}>
                        <Input
                            style={{ marginBottom: "10px" }}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: classes.InputH,
                                }
                            }}
                            label='Audio Name'
                            className={classes.inputBox}
                            value={removeAudioFormat(modalData.name)}
                            name='name'
                            onChange={(e) => {
                                const { value } = e.target
                                setModalData((prev) => ({ ...prev, name: value }))
                            }}
                        />
                        <>
                            <label htmlFor='image'>
                                <div
                                    style={{ padding: 5, cursor: 'pointer', backgroundColor: "#7b67ed", color: "#fff" }}
                                    className={classes.ImageAddButtonmodal}
                                >
                                    Select Image
                                </div>
                            </label>
                            <input
                                type="file"
                                id={`image`}
                                style={{ display: "none", cursor: 'pointer' }}
                                accept='image/png, image/jpeg, image/jpg'
                                onChange={(e) => {
                                    if (e.target && e?.target?.files) {
                                        const file = e.target.files[0];
                                        const reader = new FileReader();
                                        reader.readAsDataURL(e?.target?.files[0]);
                                        reader.onload = () => {
                                            setModalData((prev) => ({
                                                ...prev,
                                                image: file,
                                                image_url: reader.result
                                            }))
                                        };
                                        reader.onerror = () => {
                                            console.log(reader.error);
                                        };
                                    }
                                }}
                            />
                            {modalData.image_url && modalData.image_url.length > 0 ? <img
                                src={modalData.image_url.length > 0 ? modalData.image_url : ""}
                                style={{
                                    marginTop: "10px",
                                    objectFit: 'cover',
                                    objectPosition: 'top',
                                    height: '150px',
                                    width: '150px',
                                }}
                            />
                                :
                                <div style={{
                                    marginTop: "10px",
                                    height: '150px',
                                    width: '150px',
                                    backgroundColor: "#d6d0f7",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <img
                                        src={logo}
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            height: '55px',
                                            width: '50px',
                                            backgroundColor: "#d6d0f7"
                                        }}
                                    />
                                </div>
                            }
                        </>
                        {modalType == 'add' &&
                            <>
                                <label htmlFor='audio'>
                                    <div
                                        style={{ padding: 5, cursor: 'pointer', backgroundColor: "#7b67ed", color: "#fff" }}
                                        className={classes.ImageAddButtonmodal}
                                    >
                                        Select Audio
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id={`audio`}
                                    style={{ display: "none" }}
                                    accept='audio/*'
                                    onChange={(e) => {
                                        if (e.target && e?.target?.files) {
                                            const file = e.target.files[0];
                                            const reader = new FileReader();
                                            reader.readAsDataURL(e?.target && e?.target?.files[0]);
                                            reader.onload = () => {
                                                setModalData((prev) => ({
                                                    ...prev,
                                                    audio: file,
                                                    audio_url: reader.result
                                                }))
                                            };
                                            reader.onerror = () => {
                                                console.log(reader.error);
                                            };
                                        }
                                    }}
                                />
                                <audio
                                    style={{ marginTop: "10px" }}
                                    controls
                                    src={modalData.audio_url}>
                                </audio>
                            </>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        color="primary"
                        onClick={() => audioModalClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={"contained"}
                        color="primary"
                        onClick={modalType == 'add' ? handleAddAudioAPI : handleEditAudioAPI}
                    >
                        {buttonloading && <CircularProgress color='#aba0eb' size={20} style={{ marginRight: "10px" }} />}
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default withRouter(EditUser)