import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  InputAdornment,
  TextField as Input,
  CircularProgress,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
  Paper
} from '@material-ui/core'
import useStyles from "./styles";
//context
import {
  useSubscriptionsState,
  getSubscribersRequest,
} from "../../../context/SubscriptionsContext";

import { Typography } from '../../../components/Wrappers/Wrappers'
import Widget from '../../../components/Widget/Widget'

import moment from 'moment'

// Icons
import {
  Search as SearchIcon
} from '@material-ui/icons'

import config from "../../../config";

//Function to sort data
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

//Table headings
const headCells = [
  { numeric: true, disablePadding: false, label: '#', sort: false },
  { id: "name", numeric: true, disablePadding: false, label: "NAME", width: '150px', sort: true },
  { id: "profile_pic", numeric: true, center: true, disablePadding: false, label: "PROFILE PICTURE", sort: false },
  { id: "plan", numeric: true, disablePadding: false, label: "PLAN", sort: true },
  { id: 'price', numeric: true, disablePadding: false, label: 'PRICE', sort: false },
  { id: 'device_type', numeric: true, disablePadding: false, center: true, label: 'DEVICE', sort: true },
  { id: "subscribed_at", numeric: true, disablePadding: false, label: "SUBSCRIBED AT", sort: true },
  { id: "expires_at", numeric: true, disablePadding: false, label: "EXPIRES AT", sort: true },
];

//It will create table heading
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            width={headCell.width}
            key={index}
            align={headCell.center ? 'center' : headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  noWrap
                  weight={'medium'}
                  variant={'body2'}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
              :
              <Typography
                noWrap
                weight={'medium'}
                variant={'body2'}
              >
                {headCell.label}
              </Typography>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

//Subscribers component
const Subscribers = (props) => {
  const classes = useStyles();
  const context = useSubscriptionsState();
  var [subscribers, setBackSubscribers] = useState(
    context.subscribers.subscribers
  );
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('subscribed_at')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [subscribersRows, setSubscribersRows] = useState(context.subscribers.subscribers)
  const [searchVal, setSearchVal] = useState('');

  //React hook which calls when page loads
  useEffect(() => {
    getSubscribersRequest(context.setSubscribersList);
  }, []); // eslint-disable-line

  //React hook which calls when context value change
  useEffect(() => {
    setBackSubscribers(context.subscribers.subscribers);
    setSubscribersRows(context.subscribers.subscribers);
  }, [context]);

  //Function to store Sort by and property to sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  //Function to call when pagination value change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Function to set value of rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Function to search
  const handleSearch = e => {
    let value = e.currentTarget.value.replace(/^\s+/, "")
    setSearchVal(value)
    setPage(0)
    const newArr = subscribersRows.data.filter(c => {
      return c.name && (c.name.toLowerCase().includes(value.toLowerCase()) || c.plan.toLowerCase().includes(value.toLowerCase()))
    })
    setBackSubscribers({ isLoaded: true, data: newArr })
  }

  return (
    <Grid container spacing={3}>
      <span className={classes.mainPageTitle}>Subscribers</span>
      <Grid item xs={12}>
        <Widget inheritHeight>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-end'}
            >
              <Input
                id="search-field"
                label="Search"
                margin="dense"
                variant="outlined"
                onChange={(e) => handleSearch(e)}
                value={searchVal}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Widget>
      </Grid>
      {!context.subscribers.isLoaded || !subscribers.data ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table">

                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={subscribers.data.length}
                />
                {subscribers.data.length > 0 ?
                  <TableBody>
                    {stableSort(subscribers.data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"

                            >
                              <Typography
                                variant={'body2'}
                              >
                                {(page * rowsPerPage) + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell align="left"
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <Typography
                                  variant={'body2'}
                                >
                                  {row.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                            >
                              <Box
                                display={"flex"}
                                flexWrap={"nowrap"}
                                alignItems={"center"}
                                style={{ height: '50px', width: '50px', margin: '0 auto' }}
                              >
                                <img src={row.profile_pic} className={classes.profilePicture} alt='profile' />
                              </Box>
                            </TableCell>
                            <TableCell>{row?.plan_id == 2 ? 'Plus' : row?.plan_id == 3 ? 'Pro' : '--'}</TableCell>
                            <TableCell>{row?.price === "Trial" ? "" + row?.price : "$" + row?.price}</TableCell>
                            <TableCell>{row.device_type == 0 ? 'iOS' : 'Android'}</TableCell>
                            <TableCell>{moment(row.subscribed_at * 1000).format("MM-DD-YYYY hh:mm:ss A")}</TableCell>
                            <TableCell>{moment(row.expires_at * 1000).format("MM-DD-YYYY hh:mm:ss A")}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  :
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={headCells.length}>
                        <Typography
                          style={{ textAlign: 'center', padding: '10px 0px' }}
                          noWrap
                          variant={'h4'}>No Record Found</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={config.defaultRowsPerPage}
              component="div"
              count={subscribers.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default Subscribers
