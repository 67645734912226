import React from "react";
import {
  Grid
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

//This page is for app to show switch plan process for iOS device
function SwitchPlanIos(props) {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.mainContainer}>
        <h3 className={classes.mainTitle}>How do I change my TrakMeet plan if I am being billed through iTunes?</h3>
        <p>If you want to change your TrakMeet plan and are currently billed through iTunes, access your iTunes account via TrakMeet or directly through iTunes and follow the steps below. If you'd prefer step-by-step instructions with screenshots, please visit Apple's <a href="https://support.apple.com/en-gb/HT202039">View, change, or cancel your subscriptions</a> article.</p>

        <h3 className={classes.subTitle}>Change your plan via iTunes on a computer</h3>
        <ul className={classes.mainList}>
          <li>Download or open iTunes.</li>
          <li>After iTunes has opened, select Accounts under Quick Links on the right.</li>
          <li>In the dialog box, enter your iTunes credentials and select Sign In.</li>
          <li>On the Account Information page, scroll down to Settings and select Manage next to Subscriptions.</li>
          <li>On the <span className={classes.normalBoldText}>Account Information</span> screen, scroll to the <span className={classes.normalBoldText}>Settings</span> section.</li>
          <li>You will be directed to the Edit Subscription page. Under Renewal Options, select the plan you would like to change to.</li>
          <li>A dialog box will open asking you to confirm your selection. Select Confirm.</li>
          <li>Your account will display your new plan.</li>
        </ul>

        <h3 className={classes.subTitle}>Change your plan via iTunes on a iPad, iPod, or iPhone</h3>
        <ul className={classes.mainList}>
          <li>Open the iTunes store app.</li>
          <li>Scroll to the bottom and select <span className={classes.normalBoldText}>Apple ID</span>.</li>
          <li>After selecting <span className={classes.normalBoldText}>Apple ID</span>, there will be a prompt asking you to <span className={classes.normalBoldText}>Sign In</span></li>
          <li>Once signed in, select <span className={classes.normalBoldText}>Manage</span> under <span className={classes.normalBoldText}>Subscriptions</span> on the <span className={classes.normalBoldText}>Account Settings</span> page. Then select the TrakMeet subscription.</li>
          <li>After selecting TrakMeet, you will be directed to the <span className={classes.normalBoldText}>Edit Subscription</span> page. Under <span className={classes.normalBoldText}>Renewal Options</span>, select the plan you would like to change to.</li>
          <li>A dialog box will open asking you to confirm your selection. Select <span className={classes.normalBoldText}>Confirm</span>.</li>
          <li>Your account will display your new plan.</li>
        </ul>
      </div>
    </Grid>
  );
}

export default withRouter(SwitchPlanIos);
