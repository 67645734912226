import alertify from 'alertifyjs';
import '../../node_modules/alertifyjs/build/css/themes/default.min.css';
import '../../node_modules/alertifyjs/build/css/alertify.min.css';
import config from '../config';
import axios from 'axios';
import history from './history';

var pendingRequests = [];
var refreshTokenInProgress = false

// A function to return the validation messages
export const validate = (error) => {
    let msg = '';
    if (error.details[0].type.includes('empty')) {
        msg = error.details[0].context.key.replace(/_/g, ' ') + ' is required!';
        msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    else if (error.details[0].type.includes('min')) {
        msg = error.details[0].context.key.replace(/_/g, ' ') + ' length must be at least ' + error.details[0].context.limit + ' characters long!';
        msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    else if (error.details[0].type.includes('max')) {
        msg = error.details[0].context.key.replace(/_/g, ' ') + ' length must be less than or equal to ' + error.details[0].context.limit + ' characters long!';
        msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    else if (error.details[0].type.includes('allowOnly')) {
        msg = 'Password and confirm password must be same!';
        msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    }
    else {
        msg = 'Please enter a valid ' + error.details[0].context.key.replace(/_/g, ' ') + '!';
    }
    let result = { error: msg, errorField: error.details[0].context.key }
    return result;
};

// A function to show alerts
export const displayLog = (code, message) => {
    alertify.dismissAll();
    alertify.set('notifier', 'position', 'top-right');
    // alertify.set('notifier','delay', 10);
    if (code == '1') {
        alertify.success(message);
    } else if (code == '0') {
        alertify.error(message);
    } else {
        alertify.log(message);
    }
};

// A function to encrypt the text
export const encrypt = (message) => {                            // encrypt message
    let cipherText = crypto.AES.encrypt(message, config.key).toString();
    return cipherText;
};

// A function to decrypt the text
export const decrypt = (cipherText) => {                         // decrypt cipherText
    let message = crypto.AES.decrypt(cipherText, config.key).toString(crypto.enc.Utf8);
    return message;
};

// A  common function to logout the user
export const logout = () => {
    localStorage.clear();
    history.push(process.env.PUBLIC_URL + '/login')
}

// A common function to make every API calls
export const apiCall = (method, actionType, url, reqData, headers, params, dispatch) => {
    var tokenExist;
    return axios({
        method: method,
        url: config.apiBaseURL + url,
        data: reqData,
        headers: headers,
        params: params
    })
        .then((response) => {

            if (response && response.data && response.data.code === 401) {
                if (localStorage.getItem('authorization')) {
                    tokenExist = false;
                    var tokenHeader = {};
                    tokenHeader.refresh_token = config.defaultRefreshToken;
                    tokenHeader.auth_token = localStorage.getItem('authorization');
                    tokenHeader.language = config.language;
                    if (!tokenExist) {
                        // If it's 401 code in response, store upcomming requests in array
                        pendingRequests.push({
                            method: method,
                            actionType: actionType,
                            url: config.apiBaseURL + url,
                            data: reqData,
                            headers: headers,
                            params: params,
                            dispatch: dispatch
                        });
                        if (!refreshTokenInProgress) {
                            refreshTokenInProgress = true
                            return axios({
                                method: 'POST',
                                url: config.apiBaseURL + '/admin/refreshToken',
                                // data: tokenData,
                                headers: tokenHeader
                            })
                                .then((response) => {

                                    if (response.data.code == 1) {
                                        localStorage.setItem('authorization', response.data.data.auth_token);
                                        // localStorage.setItem('refresh_token', response.data.data.refresh_token);
                                        tokenExist = true;
                                        if (response.data.data.auth_token == localStorage.getItem('authorization')) {
                                            if (pendingRequests.length > 0) {
                                                setTimeout(() => {
                                                    callPendingRequests(pendingRequests);
                                                }, 1000);
                                            }
                                        }
                                    }
                                    else {
                                        alertify.dismissAll();
                                        return alertify.alert()
                                            .setting({
                                                'label': 'OK',
                                                'message': 'Session expired, please login again!',
                                                'closable': false,
                                                'movable': false,
                                                'onok': function () {
                                                    logout()
                                                    history.push(process.env.PUBLIC_URL + '/login')
                                                },
                                            }).setHeader('<em>  </em> ').show();
                                    }
                                })
                                .catch((error) => {
                                    console.log('refreshToken error:::', error)
                                });
                        }
                    }
                }
            } else {
                return response;
            }
        })
        .catch((error) => {

            //If Error.response.status===401, Call refresh token api
            if (typeof (error) != 'undefined' && typeof (error.response) != 'undefined' && error.response.status === 401) {
                if (localStorage.getItem('refresh_token') && localStorage.getItem('authorization')) {
                    tokenExist = false;
                    var tokenData = {};
                    tokenData.refresh_token = localStorage.getItem('refresh_token');
                    var tokenHeader = {};
                    tokenHeader.authorization = localStorage.getItem('authorization');
                    tokenHeader.language = config.language;
                    if (!tokenExist) {
                        // If it's 401 code in response, store upcomming requests in array
                        pendingRequests.push({
                            method: method,
                            actionType: actionType,
                            url: config.apiBaseURL + url,
                            data: reqData,
                            headers: headers,
                            params: params
                        });
                    }
                    return axios({
                        method: 'POST',
                        url: config.apiBaseURL + 'refresh-token',
                        data: tokenData,
                        headers: tokenHeader
                    })
                        .then((response) => {
                            if (response.data.code == 1) {
                                localStorage.setItem('authorization', response.data.data.auth_token);
                                localStorage.setItem('refresh_token', response.data.data.refresh_token);
                                tokenExist = true;
                                if (response.data.data.auth_token == localStorage.getItem('authorization')) {
                                    if (pendingRequests.length > 0) {
                                        setTimeout(() => {
                                            callPendingRequests(pendingRequests);
                                        }, 1000);
                                    }
                                }
                            }
                            else {
                                alertify.dismissAll();
                                return alertify.alert()
                                    .setting({
                                        'label': 'OK',
                                        'message': 'Session expired, please login again!',
                                        'closable': false,
                                        'movable': false,
                                        'onok': function () {
                                            logout()
                                            history.push(process.env.PUBLIC_URL + '/login')
                                        },
                                    }).setHeader('<em>  </em> ').show();
                            }
                        })
                        .catch((error) => {
                            console.log('refreshToken error:::', error)
                        });
                }
            }
            else {
                alertify.dismissAll();
                return alertify.alert()
                    .setting({
                        'label': 'OK',
                        'message': 'The network connection is lost!',
                        'closable': false,
                        'movable': false,
                        'onok': function () {
                            history.push(process.env.PUBLIC_URL + '/app')
                        },
                    }).setHeader('<em> </em> ').show();

            }
        })
}

// Call all pending API calls after calling refresh token api
export const callPendingRequests = (allPendingRequests) => {
    let reqCnt;
    for (reqCnt = 0; reqCnt < allPendingRequests.length; reqCnt++) {
        let actionType = allPendingRequests[reqCnt].actionType;
        let dispatch = allPendingRequests[reqCnt].dispatch;
        axios.defaults.headers.common["auth_token"] = localStorage.getItem('authorization');


        axios(allPendingRequests[reqCnt])
            .then((response) => {


                dispatch({
                    type: actionType,
                    payload: response.data
                })
            })
            .catch((error) => {
                console.log('ERROR in pending request:::', error);
            });
    }
    refreshTokenInProgress = false
    pendingRequests = [];
};