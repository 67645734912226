import React from "react";
import {
  Grid
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";
import classNames from "classnames";


//This page is for app to show In App Purchase cancelation process for android device
function CancelAndroidIAP(props) {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.mainContainer}>
        <h3 className={classes.mainTitle}>How do I cancel billing for TrakMeet?</h3>
        <h3 className={classes.subTitle}>Android</h3>
        <ul className={classes.mainList}>
          <li>Go to <a href="https://play.google.com/store/account">https://play.google.com/store/account</a>.</li>
          <li>Find the subscription you want to cancel.</li>
          <li>Click Cancel Subscription.</li>
        </ul>
        <p className={classNames(
          classes.normalBoldText,
          classes.welcomeText
        )}>Important:</p>
        <p className={classes.normalBoldText}>Uninstalling an app will not automatically stop your subscription. You must cancel your subscription to end your subscription. If you uninstall the app without canceling your subscription, you will still be charged. If an app you've purchased a subscription for is removed from Google Play, your future subscription will be automatically canceled but past subscriptions will not be refunded.</p>
      </div>
    </Grid>
  );
}

export default withRouter(CancelAndroidIAP);
