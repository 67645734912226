import React, { useState } from 'react'
import {
  Grid,
  Box,
  Tabs,
  Tab
} from '@material-ui/core'

import Widget from '../../components/Widget'

import useStyles from "./styles";

import RegisteredUserReports from './registeredUsersReports/RegisteredUserReports';
import SubscribedUserReports from './subscribedUsersReports/SubscribedUserReports';

//Reports component
const Reports = (props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0)

  //Function call to set tab value when tab change
  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <>
      <Grid container spacing={3}>
        <span className={classes.mainPageTitle}>Report Management</span>
        <Grid item xs={12}>
          <Widget>
            <Box display={'flex'} justifyContent={'center'}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tab}
                onChange={handleChangeTab}
                aria-label="full width tabs example"
              >
                <Tab label="USERS REGISTERED" />
                <Tab label="USERS SUBSCRIBED" />
              </Tabs>
            </Box>
          </Widget>
        </Grid>
      </Grid>
      {tab === 0 ?
        <RegisteredUserReports />
        :
        <SubscribedUserReports />

      }
    </>
  )
}

export default Reports
