import React from 'react'
import { apiCall, displayLog } from '../common/common'
import config from '../config'

const AdminUsersContext = React.createContext()

const rootReducer = (state, action) => {
    switch (action.type) {
        case 'GET_ADMIN_USERS':
            return {
                isLoaded: true,
                users: action.payload,
            }

        case 'CREATE_ADMIN_USER':
            return {
                ...state,
                isLoaded: true,
            }

        case 'UPDATE_ADMIN_USER':
            return {
                ...state,
                isLoaded: true,
            }
        default:
            return {
                ...state,
            }
    }
}

const AdminUserProvider = ({ children }) => {
    const [adminUsers, setAdminUsers] = React.useReducer(rootReducer, {
        isLoaded: false,
        users: [],
    })
    return (
        <AdminUsersContext.Provider value={{ adminUsers, setAdminUsers }}>
            {children}
        </AdminUsersContext.Provider>
    )
}

const useAdminUsersState = () => {
    const context = React.useContext(AdminUsersContext)
    return context
}

//API call to get all users
export async function getAdminUsersRequest(dispatch) {
    let res = await apiCall(
        'GET',
        'GET_ADMIN_USERS',
        '/admin/listofAdminUser',
        {},
        {},
        {},
        dispatch
    )
    if (res && res.data) {
        if (res.data.code === 0) {
            displayLog(0, res.data.message)
        } else {
            dispatch({ type: 'GET_ADMIN_USERS', payload: res?.data?.data?.adminUser })
        }
    }
}

export async function createAdminUser(dispatch, reqData) {
    let res = await apiCall(
        "POST",
        "",
        "/admin/createAdminUser",
        reqData,
        {},
        {},
        dispatch
    );
    if (res && res.data) {
        if (res.data.code == 0) {
            displayLog(0, res.data.message)
            return res
        } else {
            dispatch({ type: "CREATE_ADMIN_USER" })
            displayLog(1, res.data.message)
            return res
        }
    }
}

export async function updateAdminUser(dispatch, reqData) {
    let res = await apiCall(
        "POST",
        "",
        "/admin/updateAdminUser",
        reqData,
        {},
        {},
        dispatch
    );
    if (res && res.data) {
        if (res.data.code == 0) {
            displayLog(0, res.data.message);
            return res
        } else {
            dispatch({ type: "UPDATE_ADMIN_USER" })
            displayLog(1, res.data.message);
            return res
        }
    }
}

export async function deleteAdminUser(dispatch, reqData) {
    let res = await apiCall(
        "POST",
        "",
        "/admin/deleteAdminUser",
        reqData,
        {},
        {},
        dispatch
    );
    if (res && res.data) {
        if (res.data.code == 0) {
            displayLog(0, res.data.message)
        } else {
            dispatch({ type: "UPDATE_ADMIN_USER" })
            displayLog(1, res.data.message)
        }
    }
}

export async function sendResetRequest(dispatch, reqData) {
    let res = await apiCall('POST', 'GET_ADMIN_USERS', '/admin/forgotPassword', reqData, {
        'language': config.language,
        'auth_token': config.defaultAuthToken,
        'Content-Type': config.contentType
    }, {}, dispatch)
    if (res && res.data) {
        if (res.data.code === 0) {
            displayLog(0, res.data.message)
        } else {
            displayLog(1, res.data.message)
        }
    }
}

export async function handleBlockAdminUsers(dispatch, reqData) {
    let res = await apiCall('POST', 'GET_ADMIN_USERS', '/admin/banUsers', reqData, {}, {}, dispatch)
    if (res && res.data) {
        if (res.data.code === 0) {
            displayLog(0, res.data.message)
        } else {
            displayLog(1, res.data.message)
        }
    }
}

export { AdminUserProvider, AdminUsersContext, useAdminUsersState }
