import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { Box, Grid, Breadcrumbs, Tabs, Tab } from '@material-ui/core'
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'
import Widget from '../Widget'
import { Typography } from '../../components/Wrappers'

// pages
import Dashboard from '../../pages/dashboard'
import Genre from '../../pages/genre/Genre'
import Talents from '../../pages/talents/Talents'
import Users from '../../pages/users'
import UserDetails from '../../pages/users/UserDetails'
import Reports from '../../pages/reports'
import Subscriptions from '../../pages/subscriptions/plans'
import Subscribers from '../../pages/subscriptions/subscribers'

// context
import { useLayoutState } from '../../context/LayoutContext'
import { DashboardProvider } from '../../context/DashboardContext'
import { GenreProvider } from '../../context/GenreContext'
import { TalentsProvider } from '../../context/TalentsContext'
import { UsersProvider } from '../../context/UsersContext'
import { ReportsProvider } from '../../context/ReportsContext'
import { SubscriptionsProvider } from '../../context/SubscriptionsContext'

//Sidebar structure
import structure from '../Sidebar/SidebarStructure'
import ReferralUser from '../../pages/referralcodeUsers/ReferralUser'
import { ReferralUserProvider } from '../../context/ReferralUserContext'
import AddUser from '../../pages/users/AddUser'
import EditUser from '../../pages/users/EditUser'
import { AdminUserProvider } from '../../context/AdminUsersContext'
import AdminUsers from '../../pages/adminUsers/AdminUsers'


// Tab styling

const CustomTab = withStyles(theme => ({
    root: {
        minWidth: 72,
        textTransform: 'none',
        fontWeight: 400,
    },
}))(props => <Tab {...props} />)

function Layout(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(2)
    // global
    var layoutState = useLayoutState()

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const [admintype, setAdminType] = useState('1');

    useEffect(() => {
        getAdminType();
    }, []);

    const getAdminType = async () => {
        const admin_type = await localStorage.getItem('ADMIN_TYPE')
        setAdminType(admin_type && admin_type.toString());
    }

    return (
        <div className={classes.root}>
            <Header history={props.history} />
            <Sidebar structure={admintype == "3" ? structure.structure2 : structure.structure1} />
            <div
                className={classnames(classes.content, {
                    [classes.contentShift]: layoutState.isSidebarOpened,
                })}
            >
                <Widget
                    disableWidgetMenu
                    inheritHeight
                    className={classes.margin}
                    bodyClass={classes.navPadding}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap={'nowrap'}
                        style={{ overflowX: 'auto' }}
                    >
                        {(admintype == "3" ? structure.structure2 : structure.structure1).map(c => {
                            if (
                                !c.children &&
                                window.location.hash.includes(c.link) &&
                                c.link
                            ) {
                                return (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        key={c.id}
                                    >
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Typography variant="h4">
                                                {c.label}
                                            </Typography>
                                        </Breadcrumbs>
                                        {window.location.hash.includes(
                                            '/app/dashboard'
                                        ) && (
                                                <Tabs
                                                    value={value}
                                                    onChange={handleChange}
                                                    aria-label="simple tabs example"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    style={{ marginLeft: 38 }}
                                                >
                                                    <CustomTab
                                                        label="Today"
                                                        {...a11yProps(0)}
                                                    />
                                                    <CustomTab
                                                        label="This week"
                                                        {...a11yProps(1)}
                                                    />
                                                    <CustomTab
                                                        label="This month"
                                                        {...a11yProps(2)}
                                                    />
                                                    <CustomTab
                                                        label="This year"
                                                        {...a11yProps(3)}
                                                    />
                                                </Tabs>
                                            )}
                                    </Box>
                                )
                            } else if (c.children) {
                                return c.children.map(currentInner => {
                                    if (
                                        window.location.hash.includes(
                                            currentInner.link
                                        )
                                    ) {
                                        return (
                                            <Breadcrumbs
                                                separator={
                                                    <NavigateNextIcon fontSize="small" />
                                                }
                                                aria-label="breadcrumb"
                                                key={c.id}
                                            >
                                                <Typography variant={'h6'}>
                                                    {c.label}
                                                </Typography>
                                                <Typography
                                                    variant={'h6'}
                                                    color="primary"
                                                >
                                                    {currentInner.label}
                                                </Typography>
                                            </Breadcrumbs>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            } else {
                                return null
                            }
                        })}
                    </Grid>
                </Widget>
                <Switch>
                    <Route
                        path={process.env.PUBLIC_URL + '/app/dashboard'}
                        exact
                    >
                        <DashboardProvider>
                            <Dashboard />
                        </DashboardProvider>
                    </Route>
                    <Route path={process.env.PUBLIC_URL + '/app/genre'} exact>
                        <GenreProvider>
                            <Genre />
                        </GenreProvider>
                    </Route>
                    <Route path={process.env.PUBLIC_URL + '/app/talent'} exact>
                        <TalentsProvider>
                            <Talents />
                        </TalentsProvider>
                    </Route>
                    <Route path={process.env.PUBLIC_URL + '/app/users'} exact>
                        <UsersProvider>
                            <Users />
                        </UsersProvider>
                    </Route>
                    <Route
                        path={process.env.PUBLIC_URL + '/app/users/details'}
                        exact
                    >
                        <UsersProvider>
                            <UserDetails />
                        </UsersProvider>
                    </Route>
                    <Route
                        path={process.env.PUBLIC_URL + '/app/users/adduser'}
                        exact
                    >
                        <UsersProvider>
                            <AddUser />
                        </UsersProvider>
                    </Route>
                    <Route
                        path={process.env.PUBLIC_URL + '/app/users/edituser'}
                        exact
                    >
                        <UsersProvider>
                            <EditUser />
                        </UsersProvider>
                    </Route>
                    <Route path={process.env.PUBLIC_URL + '/app/reports'} exact>
                        <ReportsProvider>
                            <Reports />
                        </ReportsProvider>
                    </Route>
                    <Route
                        exact
                        path={process.env.PUBLIC_URL + '/app/subscriptions'}
                        render={() => (
                            <Redirect
                                to={
                                    process.env.PUBLIC_URL +
                                    '/app/subscriptions/plans'
                                }
                            />
                        )}
                    />
                    <Route
                        path={
                            process.env.PUBLIC_URL + '/app/subscriptions/plans'
                        }
                        exact
                    >
                        <SubscriptionsProvider>
                            <Subscriptions />
                        </SubscriptionsProvider>
                    </Route>
                    <Route
                        path={
                            process.env.PUBLIC_URL +
                            '/app/subscriptions/subscribers'
                        }
                        exact
                    >
                        <SubscriptionsProvider>
                            <Subscribers />
                        </SubscriptionsProvider>
                    </Route>
                    <Route
                        path={process.env.PUBLIC_URL + '/app/referraluser'}
                        exact
                    >
                        <ReferralUserProvider>
                            <ReferralUser />
                        </ReferralUserProvider>
                    </Route>
                    <Route
                        path={process.env.PUBLIC_URL + '/app/adminuser'}
                        exact
                    >
                        <AdminUserProvider>
                            <AdminUsers />
                        </AdminUserProvider>
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default withRouter(Layout)
