import React from "react";
import { apiCall, displayLog } from "../common/common";

const TalentsContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case "GET_TALENTS":
      return {
        isLoaded: true,
        talents: action.payload,
      };
    case "EDIT_TALENT_STATUS":
      return {
        ...state,
        isLoaded: true
      };
    case "DELETE_TALENT":
      return {
        ...state,
        isLoaded: true
      };
    case "EDIT_TALENT":
      return {
        ...state,
        isLoaded: true
      };
    case "CREATE_TALENT":
      return {
        ...state,
        isLoaded: true
      };

    default:
      return {
        ...state
      };
  }
};

const TalentsProvider = ({ children }) => {

  const [talents, setTalents] = React.useReducer(rootReducer, {
    isLoaded: false,
    talents: []
  });
  return (
    <TalentsContext.Provider value={{ talents, setTalents }}>
      {children}
    </TalentsContext.Provider>
  );
};

const useTalentState = () => {
  const context = React.useContext(TalentsContext);
  return context;
};

//API call to get all talents
export async function getTalentsRequest(dispatch) {
  let res = await apiCall('GET', 'GET_TALENTS', '/admin/talent_type', {}, {}, { is_admin: 1 }, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_TALENTS", payload: res.data });
    }
  }
}

//API call to update talent type status
export async function updateTalentStatus(dispatch, reqData) {
  let res = await apiCall('PUT', 'EDIT_TALENT_STATUS', '/admin/talent_typeStatus', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "EDIT_TALENT_STATUS" });
    }
  }
}

//API call to delete particular talent type
export async function deleteTalent(dispatch, reqData) {
  let res = await apiCall('DELETE', 'DELETE_TALENT', '/admin/talent_type', {}, {}, reqData, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "DELETE_TALENT" });
    }
  }
}

//API call to update talent type
export async function updateTalent(dispatch, reqData) {
  let res = await apiCall('PUT', 'EDIT_TALENT', '/admin/talent_type', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "EDIT_TALENT" });
    }
  }
}

//API call to create new talent type
export async function createTalent(dispatch, reqData) {
  let res = await apiCall('POST', 'CREATE_TALENT', '/admin/talent_type', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "CREATE_TALENT" });
    }
  }
}

export { TalentsProvider, TalentsContext, useTalentState };
