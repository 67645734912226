import React from 'react'
import {
    Home as HomeIcon,
    Help as HelpIcon,
    Work as WorkIcon,
    School as SchoolIcon,
    Group as GroupIcon,
    Assessment as AssessmentIcon,
    MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons'

const structure1 = [
    {
        id: 0,
        label: 'Dashboard',
        link: process.env.PUBLIC_URL + '/app/dashboard',
        icon: <HomeIcon />,
    },
    {
        id: 1,
        label: 'Genre',
        link: process.env.PUBLIC_URL + '/app/genre',
        icon: <WorkIcon />,
        // children: [
        //     {
        //         label: 'Genre List',
        //         link: process.env.PUBLIC_URL + '/app/genre/list',
        //     },
        // ],
    },
    {
        id: 2,
        label: 'Talent',
        link: process.env.PUBLIC_URL + '/app/talent',
        icon: <SchoolIcon />,
        // children: [
        //     {
        //         label: 'Education List',
        //         link: process.env.PUBLIC_URL + '/app/talent/list',
        //     },
        // ],
    },
    {
        id: 3,
        label: 'Users',
        link: process.env.PUBLIC_URL + '/app/users',
        icon: <GroupIcon />,
        // children: [
        //     {
        //         label: 'Users List',
        //         link: process.env.PUBLIC_URL + '/app/users/list',
        //     },
        // ],
    },
    {
        id: 4,
        label: 'Reports',
        link: process.env.PUBLIC_URL + '/app/reports',
        icon: <AssessmentIcon />,
        // children: [
        //     {
        //         label: 'Reported Users',
        //         link: process.env.PUBLIC_URL + '/app/reports/list',
        //     },
        // ],
    },
    {
        id: 5,
        label: 'Subscriptions',
        link: process.env.PUBLIC_URL + '/app/subscriptions',
        icon: <MonetizationOnIcon />,
        children: [
            // {
            //     label: 'Plans',
            //     link: process.env.PUBLIC_URL + '/app/subscriptions/plans',
            // },
            {
                label: 'Subscribers',
                link: process.env.PUBLIC_URL + '/app/subscriptions/subscribers',
            },
        ],
    },
    {
        id: 6,
        label: 'Referral Users',
        link: process.env.PUBLIC_URL + '/app/referraluser',
        icon: <GroupIcon />,
    },
]

const structure2 = [
    {
        id: 0,
        label: 'Dashboard',
        link: process.env.PUBLIC_URL + '/app/dashboard',
        icon: <HomeIcon />,
    },
    {
        id: 1,
        label: 'Genre',
        link: process.env.PUBLIC_URL + '/app/genre',
        icon: <WorkIcon />,
        // children: [
        //     {
        //         label: 'Genre List',
        //         link: process.env.PUBLIC_URL + '/app/genre/list',
        //     },
        // ],
    },
    {
        id: 2,
        label: 'Talent',
        link: process.env.PUBLIC_URL + '/app/talent',
        icon: <SchoolIcon />,
        // children: [
        //     {
        //         label: 'Education List',
        //         link: process.env.PUBLIC_URL + '/app/talent/list',
        //     },
        // ],
    },
    {
        id: 3,
        label: 'Users',
        link: process.env.PUBLIC_URL + '/app/users',
        icon: <GroupIcon />,
        // children: [
        //     {
        //         label: 'Users List',
        //         link: process.env.PUBLIC_URL + '/app/users/list',
        //     },
        // ],
    },
    {
        id: 4,
        label: 'Reports',
        link: process.env.PUBLIC_URL + '/app/reports',
        icon: <AssessmentIcon />,
        // children: [
        //     {
        //         label: 'Reported Users',
        //         link: process.env.PUBLIC_URL + '/app/reports/list',
        //     },
        // ],
    },
    {
        id: 5,
        label: 'Subscriptions',
        link: process.env.PUBLIC_URL + '/app/subscriptions',
        icon: <MonetizationOnIcon />,
        children: [
            // {
            //     label: 'Plans',
            //     link: process.env.PUBLIC_URL + '/app/subscriptions/plans',
            // },
            {
                label: 'Subscribers',
                link: process.env.PUBLIC_URL + '/app/subscriptions/subscribers',
            },
        ],
    },
    {
        id: 6,
        label: 'Referral Users',
        link: process.env.PUBLIC_URL + '/app/referraluser',
        icon: <GroupIcon />,
    },
    {
        id: 6,
        label: 'Admin Users',
        link: process.env.PUBLIC_URL + '/app/adminuser',
        icon: <GroupIcon />,
    },
];

const structure = { structure1, structure2 }

export default structure
