import React from "react";
import { apiCall, displayLog } from "../common/common";

const UsersContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        isLoaded: true,
        users: action.payload,
      };
    case "EDIT_USER_STATUS":
      return {
        ...state,
        isLoaded: true
      };
    case "USER_SUBSCRIBE":
      return {
        ...state,
        isLoaded: true
      };
    case "GET_USER_PROFILE":
      return {
        isLoaded: true,
        profile: action.payload,
      };
    default:
      return {
        ...state
      };
  }
};

const UsersProvider = ({ children }) => {

  const [users, setUsers] = React.useReducer(rootReducer, {
    isLoaded: false,
    users: []
  });
  const [profile, setUserProfile] = React.useReducer(rootReducer, {
    isLoaded: false,
    profile: []
  });
  return (
    <UsersContext.Provider value={{ users, setUsers, profile, setUserProfile }}>
      {children}
    </UsersContext.Provider>
  );
};

const useUsersState = () => {
  const context = React.useContext(UsersContext);
  return context;
};

//API call to get all users
export async function getUsersRequest(dispatch) {
  let res = await apiCall('GET', 'GET_USERS', '/admin/users', {}, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_USERS", payload: res.data });
    }
  }
}

//API call to update ban/unban status
export async function updateUserStatus(dispatch, reqData) {
  let res = await apiCall('POST', 'EDIT_USER_STATUS', '/admin/banUsers', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "EDIT_USER_STATUS" });
    }
  }
}

export async function subscribeUser(dispatch, reqData) {
  let res = await apiCall('POST', 'USER_SUBSCRIBE', '/admin/addSubscription', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({ type: "USER_SUBSCRIBE" });
    }
  }
}

//API call to get particular user profile
export async function getUserProfile(dispatch, reqData) {
  let res = await apiCall('GET', 'GET_USER_PROFILE', '/admin/userProfile', {}, {}, reqData, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_USER_PROFILE", payload: res.data });
    }
  }
}

//API call to send resetPasword link to user
export async function sendResetRequest(dispatch, reqData) {
  let res = await apiCall('POST', 'GET_USER_PROFILE', '/forgotPassword', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
    }
  }
}


export { UsersProvider, UsersContext, useUsersState };
