import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  InputAdornment,
  TextField as Input,
  CircularProgress,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import { Button, Typography } from '../../../components/Wrappers/Wrappers'
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";

import useStyles from "./styles";

//context
import {
  useSubscriptionsState,
  getSubscriptionPlansRequest,
  editSubscriptionPlanPriceRequest
} from "../../../context/SubscriptionsContext";

// Icons
import {
  AttachMoney as AttachMoneyIcon,
} from '@material-ui/icons'

import { validate } from '../../../common/common';
import joi from 'joi-browser';

//Plans component
const Plans = (props) => {
  const classes = useStyles();
  const context = useSubscriptionsState();
  var [plans, setBackSubscriptionPlans] = useState([]);
  const [toggleInputModal, setToggleInputModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //React hook which calls when page loads
  useEffect(() => {
    getSubscriptionPlansRequest(context.setSubscriptionPlans);
  }, []); // eslint-disable-line

  //React hook which calls when context value change
  useEffect(() => {
    setBackSubscriptionPlans(context.plans.plans);
  }, [context]);

  //Function to open/close modal
  const manageModal = (plans) => {
    setError('')
    setErrorField('')
    setFormValues(plans)
    setToggleInputModal(true)
  }

  //Function which calls on form submit
  const submitPlanHandler = () => {
    let reqData = {
      price: formValues.price
    }
    validateFormData(reqData);
  }

  //Function to validate form data
  const validateFormData = (body) => {
    let schema = joi.object().keys({
      price: joi.string().trim().regex(/^\d{1,4}(?:\.\d{1,2})?$/).required(),
    })
    joi.validate(body, schema, async (err, value) => {
      if (err) {
        if (err.details[0].message !== error || error.details[0].context.key !== errorField) {
          let errorLog = validate(err)
          setError(errorLog.error)
          setErrorField(errorLog.errorField)
        }
      }
      else {
        setError('')
        setErrorField('')
        setIsLoading(true)
        let reqData = {}
        reqData = {
          price: body.price,
          plan_id: formValues.plan_id
        }
        await editSubscriptionPlanPriceRequest(context.setSubscriptionPlans, reqData)
        await getSubscriptionPlansRequest(context.setSubscriptionPlans);
        setToggleInputModal(false)
        setIsLoading(false)
      }
    })
  }

  return (
    <Grid container spacing={3}>
      <span className={classes.mainPageTitle}>Subscription Plans</span>
      {!context.plans.isLoaded || !plans.data ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid container spacing={2} className={classes.plansContainer}>
          {plans.data.map((plan, index) => (
            <Grid className={classes.planWrapper} item xs={4} key={index}>
              <Paper className={classes.root}>
                <Typography style={{ fontSize: '45px', fontWeight: 500 }}>{plan.plan}</Typography>
                <Typography style={{ fontSize: '30px', fontWeight: 100 }}>{plan.plan_duration == 0 ? ''+ plan.plan_period : plan.plan_duration + " " + plan.plan_period}</Typography>
                <Typography color={'primary'} style={{ fontSize: '45px' }}>{plan.price == "SOON" ? "SOON" : '$' + plan.price}</Typography>
                {(plan.plan_period === 'Month' || plan.plan_period === 'Months') && plan.price != "SOON" ?
                  <Typography color={'primary'} style={{ fontSize: '18px' }}>${(+plan.price / (+plan.plan_duration * 4)).toFixed(2)}/Week</Typography>
                  :
                  <div style={{ margin: '27px 0px' }}></div>
                }
                {/* <Typography color={'primary'} style={{ fontSize: '20px' }}>1 WEEK FREE</Typography> */}
                {plan.plan == "Plus" &&
                  <Button variant={'contained'} color={'primary'} style={{ width: '100%', margin: '0px 0px 10px 0px', padding: '10px' }}
                    onClick={() => manageModal(plan)}>
                    Edit Price
                  </Button>
                }
              </Paper>
            </Grid>
          ))}

          <Dialog
            open={toggleInputModal}
            onClose={() => setToggleInputModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Edit Price</DialogTitle>
            <DialogContent>
              <Input
                label='Plan'
                placeholder={"Plan"}
                margin="normal"
                variant="outlined"
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input
                  },
                  disabled: true
                }}
                value={formValues.plan}
                fullWidth
              />
              <Input
                label='Price'
                placeholder={"Price"}
                margin="normal"
                variant="outlined"
                onChange={e => setFormValues({ ...formValues, price: e.target.value.replace(/[^0-9.]/g, "") })}
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon className={classes.searchIcon} />
                    </InputAdornment>
                  )
                }}
                value={formValues.price}
                fullWidth
              />
              {errorField === 'price' && <ErrorMessage error={error} />}
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px 20px" }}>
              <Button
                variant={"outlined"}
                color="primary"
                onClick={() => setToggleInputModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                onClick={submitPlanHandler}
                disabled={isLoading}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  )
}

export default Plans
