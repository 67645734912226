export default {
  apiBaseURL: "https://trakmeetadmin.io:8443/api/v1",
  // apiBaseURL: "http://192.168.1.76:8080/api/v1",
  remote: "https://flatlogic-node-backend.herokuapp.com",
  isBackend: process.env.REACT_APP_BACKEND,
  language: 'en',
  defaultAuthToken: '@#Llsjpoq$S1o08#OnbViE%ONLI&N*5Eu@exS1o!08l9tslsjpo#LOVE',
  defaultRefreshToken: 'LOVEjmLOV6G9Earypa9y5OhG3NpwLIanNEwbgaatfu3d',
  key: 'pvmkzdhgskwofksdalfjdqapfhtyabnvkghtofgdhapbvmghgahfhbuntydjsdflafdhflaflhpifnxoamc',
  emailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,18})/,  //OLD
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.{6,24})/, //NEW
  defaultRowsPerPage: [5, 10, 25],
  contentType: 'application/json',
  auth: {
    email: "admin@flatlogic.com",
    password: "password"
  }
};
