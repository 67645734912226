import React from "react";
import { apiCall, displayLog } from "../common/common";

const ReferralUserContext = React.createContext();

const rootReducer = (state, action) => {
    switch (action.type) {
        case "GET_REFERRAL_USER":
            return {
                isLoaded: true,
                users: action.payload.data,
            };
        case "GET_REFERRAL_USED_USER":
            return {
                isLoaded: true,
                userDetail: action.payload.data
            };
        default:
            return {
                ...state
            };
    }
};

const ReferralUserProvider = ({ children }) => {

    const [referralUser, setReferralUser] = React.useReducer(rootReducer, {
        isLoaded: false,
        users: []
    });
    const [referralUsedUser, setReferralUsedUser] = React.useReducer(rootReducer, {
        isLoaded: false,
        userDetail: []
    })
    return (
        <ReferralUserContext.Provider value={{ setReferralUser, referralUser, setReferralUsedUser, referralUsedUser }}>
            {children}
        </ReferralUserContext.Provider>
    );
};

const useReferralUserState = () => {
    const context = React.useContext(ReferralUserContext);
    return context;
};

//API call to get all users
export async function getUsersRequest(dispatch) {
    let res = await apiCall('GET', 'GET_REFERRAL_USER', '/admin/users', {}, {}, {}, dispatch)
    if (res && res.data) {
        if (res.data.code === 0) {
            displayLog(0, res.data.message)
        } else {
            dispatch({ type: "GET_REFERRAL_USER", payload: res.data });
        }
    }
};

export async function getReferralusedUsers(dispatch, reqData) {
    let res = await apiCall('POST', 'GET_REFERRAL_USED_USER', '/admin/referralUser', reqData, {}, {}, dispatch)
    if (res && res.data) {
        if (res.data.code === 0) {
            displayLog(0, res.data.message)
        } else {
            dispatch({ type: "GET_REFERRAL_USED_USER", payload: res.data });
        }
    }
}

export { ReferralUserProvider, ReferralUserContext, useReferralUserState };
