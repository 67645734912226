import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Grid,
    Box,
    TextField as Input,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
import Widget from '../../components/Widget/Widget';
import { Typography } from '../../components/Wrappers/Wrappers';
import useStyles from './styles';
import { useUsersState, getUserProfile } from '../../context/UsersContext';
import moment from 'moment';
import logo from '../../images/darkMusic.svg';

const UserDetails = props => {
    const classes = useStyles();
    const context = useUsersState();
    var [profile, setBackUserProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (props.location.state && props.location.state.user_id) {
            getUserProfile(context.setUserProfile, {
                user_id: props.location.state.user_id,
            });
        }
    }, []);

    useEffect(() => {
        setBackUserProfile(context.profile.profile);
        setIsLoading(false);
    }, [context]);

    const removeAudioFormat = string => {
        let new_string = string;
        const audioFormatRegex = /\.(mp3|wav|ogg|flac|aac|m4a)$/i
        if (audioFormatRegex.test(string)) {
            new_string = string.replace(audioFormatRegex, '')
        }
        return new_string;
    };

    const pronounce = (profile?.data && profile?.data?.gender == '3' && profile?.data?.is_pronouns == '1') ?
        "- he/him/his" : profile?.data?.is_pronouns == '2' ? "- she/her/hers" : profile?.data?.is_pronouns == '3' ? "- they/them/theirs" : "";

    return (
        <Grid container spacing={3}>
            <span className={classes.mainPageTitle}>User Details</span>
            {!context.profile.isLoaded || !profile.data || isLoading ? (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100vw'}
                    height={'calc(100vh - 200px)'}
                >
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <>
                    <Widget className={classes.boxContainer} noBodyPadding>
                        <Box style={{ padding: '16px' }}>
                            <Typography variant={'subtitle1'} color={'primary'}>
                                Photos
                            </Typography>
                            {profile.data.pictures.length > 0 &&
                                profile.data.pictures.map((pic, index) => {
                                    return (
                                        <img
                                            src={pic.profile_pic}
                                            alt={`profile-${index}`}
                                            key={index}
                                            className={
                                                classes.profilePictureGroup
                                            }
                                        />
                                    )
                                })}
                        </Box>
                    </Widget>
                    <Widget className={classes.boxContainer} noBodyPadding>
                        <Box style={{ padding: '16px' }}>
                            <Typography variant={'subtitle1'} color={'primary'}>
                                Basic Info
                            </Typography>
                            <Box className={classes.basicInfoContainer}>
                                <Grid md={4}>
                                    <Typography
                                        variant={'subtitle1'}
                                        color={'black'}
                                        className={classes.formLabels}
                                    >
                                        Name
                                    </Typography>
                                    <Input
                                        margin="dense"
                                        variant="outlined"
                                        InputProps={{
                                            disabled: true,
                                            classes: {
                                                input: classes.Input,
                                            },
                                        }}
                                        className={classes.inputBox}
                                        value={profile.data.name}
                                    />
                                    <Typography
                                        variant={'subtitle1'}
                                        color={'black'}
                                        className={classes.formLabels}
                                    >
                                        City
                                    </Typography>
                                    <Input
                                        margin="dense"
                                        variant="outlined"
                                        InputProps={{
                                            disabled: true,
                                            classes: {
                                                input: classes.Input,
                                            },
                                        }}
                                        className={classes.inputBox}
                                        value={profile.data.city}
                                    />
                                </Grid>
                                <Grid md={4}>
                                    <Typography
                                        variant={'subtitle1'}
                                        color={'black'}
                                        className={classes.formLabels}
                                    >
                                        Date of Birth
                                    </Typography>
                                    <Input
                                        margin="dense"
                                        variant="outlined"
                                        InputProps={{
                                            disabled: true,
                                            classes: {
                                                input: classes.Input,
                                            },
                                        }}
                                        className={classes.inputBox}
                                        value={moment(
                                            profile.data.date_of_birth
                                        ).format('MM-DD-YYYY')}
                                    />
                                    <Typography
                                        variant={'subtitle1'}
                                        color={'black'}
                                        className={classes.formLabels}
                                    >
                                        Zipcode
                                    </Typography>
                                    <Input
                                        margin="dense"
                                        variant="outlined"
                                        InputProps={{
                                            disabled: true,
                                            classes: {
                                                input: classes.Input,
                                            },
                                        }}
                                        className={classes.inputBox}
                                        value={profile.data.zipcode}
                                    />
                                </Grid>
                                <Grid md={4}>
                                    <Typography
                                        variant={'subtitle1'}
                                        color={'black'}
                                        className={classes.formLabels}
                                    >
                                        Gender
                                    </Typography>
                                    <RadioGroup
                                        aria-label="Gender"
                                        name="gender1"
                                        className={classes.group}
                                        value={profile.data.gender}
                                    >
                                        <FormControlLabel
                                            classes={{
                                                label: classes.radioLabels,
                                            }}
                                            value="1"
                                            disabled
                                            control={<Radio />}
                                            label="Male"
                                            style={{ fontSize: '13px' }}
                                        />
                                        <FormControlLabel
                                            classes={{
                                                label: classes.radioLabels,
                                            }}
                                            value="2"
                                            disabled
                                            control={<Radio />}
                                            label="Female"
                                            style={{ fontSize: '13px' }}
                                        />
                                        <FormControlLabel
                                            classes={{
                                                label: classes.radioLabels,
                                            }}
                                            value="3"
                                            disabled
                                            control={<Radio />}
                                            label={`Other ${pronounce}`}
                                            style={{ fontSize: '13px' }}
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Box>
                        </Box>
                    </Widget>
                    <Grid md={6} style={{ width: '100%' }}>
                        <Widget className={classes.boxContainer} noBodyPadding>
                            <Box style={{ padding: '16px' }}>
                                <Typography
                                    variant={'subtitle1'}
                                    color={'primary'}
                                >
                                    More About Me
                                </Typography>
                                <Box className={classes.moreInfoContainer}>
                                    <Grid md={12}>
                                        <Typography
                                            variant={'subtitle1'}
                                            color={'black'}
                                            className={classes.formLabels}
                                        >
                                            Talent Types
                                        </Typography>
                                        <FormGroup>
                                            {profile.data.talentTypes.length >
                                                0 &&
                                                profile.data.talentTypes.map(
                                                    (talent, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                classes={{
                                                                    label:
                                                                        classes.radioLabels,
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            true
                                                                        }
                                                                        value={
                                                                            talent.talent_type_id
                                                                        }
                                                                        disabled
                                                                    />
                                                                }
                                                                label={
                                                                    talent.talent_type_name
                                                                }
                                                            />
                                                        )
                                                    }
                                                )}
                                        </FormGroup>
                                        <Typography
                                            variant={'subtitle1'}
                                            color={'black'}
                                            className={classes.formLabels}
                                        >
                                            Genre
                                        </Typography>
                                        <FormGroup>
                                            {profile.data.genres.length > 0 &&
                                                profile.data.genres.map(
                                                    (genre, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                classes={{
                                                                    label:
                                                                        classes.radioLabels,
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            true
                                                                        }
                                                                        value={
                                                                            genre.genre_id
                                                                        }
                                                                        disabled
                                                                    />
                                                                }
                                                                label={
                                                                    genre.genre_name
                                                                }
                                                            />
                                                        )
                                                    }
                                                )}
                                        </FormGroup>
                                    </Grid>
                                </Box>
                            </Box>
                        </Widget>
                    </Grid>
                    <Grid md={6} style={{ width: '100%' }}>
                        <Widget className={classes.boxContainer} noBodyPadding>
                            <Box style={{ padding: '16px' }}>
                                <Typography
                                    variant={'subtitle1'}
                                    color={'primary'}
                                >
                                    My Audio Tracks
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {profile.data.audios.length > 0 &&
                                        profile.data.audios.map(
                                            (audio, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        style={{
                                                            width: '50%',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: '70px',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant={
                                                                    'subtitle1'
                                                                }
                                                                color={'black'}
                                                                className={
                                                                    classes.formLabels
                                                                }
                                                            >
                                                                {removeAudioFormat(
                                                                    audio.audio_name
                                                                )}
                                                            </Typography>
                                                        </div>
                                                        <div style={{}}>
                                                            {audio.image ? (
                                                                <img
                                                                    src={
                                                                        audio.image
                                                                            ? audio.image
                                                                            : logo
                                                                    }
                                                                    style={{
                                                                        objectFit:
                                                                            'cover',
                                                                        objectPosition:
                                                                            'top',
                                                                        height:
                                                                            '150px',
                                                                        width:
                                                                            '150px',
                                                                        alignSelf:
                                                                            'center',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        marginTop:
                                                                            '10px',
                                                                        height:
                                                                            '150px',
                                                                        width:
                                                                            '150px',
                                                                        backgroundColor:
                                                                            '#d6d0f7',
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            logo
                                                                        }
                                                                        style={{
                                                                            objectFit:
                                                                                'cover',
                                                                            objectPosition:
                                                                                'top',
                                                                            height:
                                                                                '55px',
                                                                            width:
                                                                                '50px',
                                                                            backgroundColor:
                                                                                '#d6d0f7',
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <audio
                                                            style={{
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                            controls
                                                            src={audio.audio}
                                                        ></audio>
                                                    </Box>
                                                )
                                            }
                                        )}
                                </div>
                            </Box>
                        </Widget>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default withRouter(UserDetails)
