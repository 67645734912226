import React from "react";
import { apiCall, displayLog } from "../common/common";

const DashboardContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case "GET_RECENT_USERS":
      return {
        isLoaded: true,
        users: action.payload,
      };
    case "GET_TOTAL_USERS":
      return {
        isLoaded: true,
        totalUsers: action.payload,
      };

    default:
      return {
        ...state
      };
  }
};

const DashboardProvider = ({ children }) => {

  const [users, setRecentUsers] = React.useReducer(rootReducer, {
    isLoaded: false,
    users: []
  });

  const [totalUsers, setTotalUsers] = React.useReducer(rootReducer, {
    isLoaded: false,
    totalUsers: []
  });
  return (
    <DashboardContext.Provider value={{ users, setRecentUsers, totalUsers, setTotalUsers }}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboardState = () => {
  const context = React.useContext(DashboardContext);
  return context;
};

//API call to get recently registered Users
export async function getRecentlyRegisteredUsersRequest(dispatch) {
  let res = await apiCall('GET', 'GET_RECENT_USERS', '/admin/recentlyRegistered', {}, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_RECENT_USERS", payload: res.data });
    }
  }
}

//API call to get Total users on the TrakMeet platform
export async function getTotalUsersOnPlatform(dispatch) {
  let res = await apiCall('GET', 'GET_TOTAL_USERS', '/admin/totalUsers', {}, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      dispatch({ type: "GET_TOTAL_USERS", payload: res.data });
    }
  }
}


export { DashboardProvider, DashboardContext, useDashboardState };
