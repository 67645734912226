import React from "react";
import jwt from "jsonwebtoken";

//config
import config from "../config";

import { apiCall, displayLog } from "../common/common";

var UserAuthStateContext = React.createContext();
var UserAuthDispatchContext = React.createContext();

function userAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "RESET_PASSWORD_SUCCESS":
      return { ...state };
    case "CHANGE_PASSWORD_SUCCESS":
      return { ...state };
    case "FORGOT_PASSWORD_SUCCESS":
      return { ...state };
    case "SIGN_OUT_SUCCESS":
      return { ...state };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserAuthProvider({ children }) {
  var [state, dispatch] = React.useReducer(userAuthReducer, {
    isAuthenticated: () => {
      const authorization = localStorage.getItem("authorization")
      if (config.isBackend && authorization) {
        const date = new Date().getTime() / 1000;
        const data = jwt.decode(authorization);
        return date < data.exp;
      } else if (authorization) {
        return true
      }
      return false;
    }
  });

  return (
    <UserAuthStateContext.Provider value={state}>
      <UserAuthDispatchContext.Provider value={dispatch}>
        {children}
      </UserAuthDispatchContext.Provider>
    </UserAuthStateContext.Provider>
  );
}

function useUserAuthState() {
  var context = React.useContext(UserAuthStateContext);
  if (context === undefined) {
    throw new Error("useUserAuthState must be used within a UserAuthProvider");
  }
  return context;
}

function useUserAuthDispatch() {
  var context = React.useContext(UserAuthDispatchContext);
  if (context === undefined) {
    throw new Error("useUserAuthDispatch must be used within a UserAuthProvider");
  }
  return context;
}

export { UserAuthProvider, useUserAuthState, useUserAuthDispatch, loginUser, signOut, forgotPassword, resetPassword, resetPasswordForAppUser, changePassword };

// ###########################################################

//API call to login user
async function loginUser(dispatch, reqData, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)
  let res = await apiCall('POST', 'LOGIN_SUCCESS', '/admin/login', reqData, {
    'language': config.language,
    'auth_token': config.defaultAuthToken,
    'Content-Type': config.contentType
  }, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      await localStorage.setItem('ADMIN_TYPE', res.data.data.is_admin === 3 ? '3' : res.data.data.is_admin === 2 ? '2' : '1');
      await localStorage.setItem('authorization', res.data.auth_token);
      displayLog(1, res.data.message)
      if (localStorage.getItem('authorization')) {
        dispatch({
          type: 'LOGIN_SUCCESS',
          payload: res.data.data,
        })
        history.push(process.env.PUBLIC_URL + '/app/dashboard')
      }
    }
  }
}

//API call for forgot password
async function forgotPassword(dispatch, reqData, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)
  let res = await apiCall('POST', 'FORGOT_PASSWORD_SUCCESS', '/admin/forgotPassword', reqData, {
    'language': config.language,
    'auth_token': config.defaultAuthToken,
    'Content-Type': config.contentType
  }, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({
        type: 'FORGOT_PASSWORD_SUCCESS',
      })
      history.push(process.env.PUBLIC_URL + '/login')
    }
  }
}

//API call to reset password
async function resetPassword(dispatch, reqData, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)
  let res = await apiCall('POST', 'RESET_PASSWORD_SUCCESS', '/admin/resetPassword', reqData, {
    'language': config.language,
    'auth_token': config.defaultAuthToken,
    'Content-Type': config.contentType
  }, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({
        type: 'RESET_PASSWORD_SUCCESS',
      })
      signOut(dispatch, history)
    }
  }
}

//API call to reset password for app user
async function resetPasswordForAppUser(dispatch, reqData, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)
  let res = await apiCall('POST', 'RESET_PASSWORD_SUCCESS', '/resetPassword', reqData, {
    'language': config.language,
    'auth_token': config.defaultAuthToken,
    'Content-Type': config.contentType
  }, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({
        type: 'RESET_PASSWORD_SUCCESS',
      })
    }
  }
}

//Function call to logout user
function signOut(dispatch, history) {
  localStorage.removeItem('authorization')
  document.cookie = 'authorization=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  dispatch({ type: 'SIGN_OUT_SUCCESS' })
  history.push(process.env.PUBLIC_URL + '/login')
}

//Function call to receive JWT token
export function receiveToken(authorization, dispatch) {
  let user = jwt.decode(authorization)
  localStorage.setItem('authorization', authorization)
  localStorage.setItem('theme', 'default')
  dispatch({ type: 'LOGIN_SUCCESS', payload: user })
}

//API call to change password
async function changePassword(dispatch, reqData, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)
  let res = await apiCall('POST', 'CHANGE_PASSWORD_SUCCESS', '/admin/changePassword', reqData, {}, {}, dispatch)
  if (res && res.data) {
    if (res.data.code === 0) {
      displayLog(0, res.data.message)
    } else {
      displayLog(1, res.data.message)
      dispatch({
        type: 'CHANGE_PASSWORD_SUCCESS',
      })
    }
  }
}

//Function call to init auth process
export function doInit() {
  return async (dispatch) => {
    let currentUser = null
    try {
      let authorization = localStorage.getItem('authorization')
      if (authorization) {
      }
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          currentUser,
        },
      })
    } catch (error) {
      console.log(error)

      dispatch({
        type: 'AUTH_INIT_ERROR',
        payload: error,
      })
    }
  }
}
