import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Grid,
  Box,
  InputAdornment,
  TextField as Input,
  CircularProgress,
  Switch, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Button, MenuItem, Menu, TextField,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget'
import { Typography } from '../../components/Wrappers/Wrappers'
import useStyles from "./styles";
import moment from 'moment'
//context
import {
  useUsersState,
  getUsersRequest,
  updateUserStatus,
  subscribeUser,
  sendResetRequest
} from "../../context/UsersContext";
// Icons
import {
  ArrowDropDown,
  Search as SearchIcon,
  Add as AddIcon,
  Edit,
} from '@material-ui/icons'
import config from "../../config";
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'


const month_array = [
  { id: 1, name: "1 Month" },
  { id: 2, name: "2 Months" },
  { id: 3, name: "3 Months" },
  { id: 4, name: "4 Months" },
  { id: 5, name: "5 Months" },
  { id: 6, name: "6 Months" },
  { id: 7, name: "7 Months" },
  { id: 8, name: "8 Months" },
  { id: 9, name: "9 Months" },
  { id: 10, name: "10 Months" },
  { id: 11, name: "11 Months" },
  { id: 12, name: "12 Months" },
]

//Function to sort data
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

//Table headings
const headCells1 = [
  { numeric: true, disablePadding: false, label: '#', sort: false },
  { id: "name", numeric: true, disablePadding: false, label: "NAME", width: '200px', sort: true },
  { id: "profile_pic", numeric: true, center: true, disablePadding: false, label: "PROFILE PICTURE", sort: false },
  { id: "gender", numeric: true, disablePadding: false, label: "GENDER", sort: true },
  { id: "date_of_birth", numeric: true, disablePadding: false, label: "DATE OF BIRTH", sort: true },
  { id: "total_likes", numeric: true, disablePadding: true, label: "LIKED BY", sort: true },
  { id: 'is_banned', numeric: true, disablePadding: false, label: 'STATUS', sort: false },
  { id: "created_date", numeric: true, disablePadding: false, label: "REGISTERED AT", sort: true },
  { id: "action", numeric: true, disablePadding: false, label: "ACTION", sort: false },
];
const headCells2 = [
  { numeric: true, disablePadding: false, label: '#', sort: false },
  { id: "name", numeric: true, disablePadding: false, label: "NAME", width: '200px', sort: true },
  { id: "profile_pic", numeric: true, center: true, disablePadding: false, label: "PROFILE PICTURE", sort: false },
  { id: "gender", numeric: true, disablePadding: false, label: "GENDER", sort: true },
  { id: "date_of_birth", numeric: true, disablePadding: false, label: "DATE OF BIRTH", sort: true },
  { id: "total_likes", numeric: true, disablePadding: true, label: "LIKED BY", sort: true },
  { id: 'is_banned', numeric: true, disablePadding: false, label: 'STATUS', sort: false },
  { id: "created_date", numeric: true, disablePadding: false, label: "REGISTERED AT", sort: true },
  { id: "action", numeric: true, disablePadding: false, label: "ACTION", sort: false },
  { id: "edit", numeric: true, disablePadding: false, label: "EDIT", sort: false, center: true },
];

//It will create table heading
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    admin,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {(admin == 1 ? headCells1 : headCells2).map((headCell, index) => (
          <TableCell
            width={headCell.width}
            key={index}
            align={headCell.center ? 'center' : headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  noWrap
                  weight={'medium'}
                  variant={'body2'}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
              :
              <Typography
                noWrap
                weight={'medium'}
                variant={'body2'}
              >
                {headCell.label}
              </Typography>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

//Users component
const Users = (props) => {
  const classes = useStyles();
  const context = useUsersState();
  var [users, setBackUsers] = useState(
    context.users.users
  );
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created_date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [userRows, setUsersRows] = useState(context.users.users)
  const [searchVal, setSearchVal] = useState('');
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [planValue, setPlanvalue] = useState(null);
  const [planError, setPlanError] = useState(false);
  const [monthSelectError, setMonthSelectError] = useState(false);
  const [admintype, setAdminType] = useState('1');

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [resetLinkData, setResetLinkData] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('');
  const [modalUserData, setModalUserData] = useState(null);
  const [subscriptionDate, setSubscriptionDate] = useState({
    start_date: '',
    end_date: ''
  })
  const openDropdown = Boolean(anchorEl);
  const openDropdown2 = Boolean(anchorEl2);

  //React hook which calls when page loads
  useEffect(() => {
    getUsersRequest(context.setUsers);
    getAdminType()
  }, []); // eslint-disable-line

  //React hook which calls when context value change
  useEffect(() => {
    setBackUsers(context.users.users);
    setUsersRows(context.users.users);
    if (searchVal !== '') {
      const newArr = context.users.users.data.filter(c => {
        return c.name && (c.name.toLowerCase().includes(searchVal.toLowerCase()) || c.gender.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setBackUsers({ isLoaded: true, data: newArr })
    }
  }, [context]);

  //Function to store Sort by and property to sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const getAdminType = async () => {
    const admin_type = await localStorage.getItem('ADMIN_TYPE')
    setAdminType(admin_type.toString());
  }
  //Function to call when pagination value change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //Function to set value of rows per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Function to search
  const handleSearch = e => {
    let value = e.currentTarget.value.replace(/^\s+/, "")
    setSearchVal(value)
    setPage(0)
    const newArr = userRows.data.filter(c => {
      return c.name && (c.name.toLowerCase().includes(value.toLowerCase()) || c.gender.toLowerCase().includes(value.toLowerCase()))
    })
    setBackUsers({ isLoaded: true, data: newArr })
  }

  //Function call to ban/unban user
  const handleChange = async (event, user_id) => {
    await updateUserStatus(context.setUsers, {
      is_banned: event.target.checked ? 0 : 1,
      user_id: user_id
    });
    await getUsersRequest(context.setUsers);
  };

  //Function call to redirect to user details screen
  const redirectToDetails = async (user_id) => {
    props.history.push({
      pathname: process.env.PUBLIC_URL + '/app/users/details',
      state: { user_id: user_id }
    })
  }

  //Function call to create user
  const onCreateUserClicked = async (user_id) => {
    props.history.push({
      pathname: process.env.PUBLIC_URL + '/app/users/adduser',
    })
  }

  //Function call to edit user
  const onEditUserClicked = async (item) => {
    props.history.push({
      pathname: process.env.PUBLIC_URL + '/app/users/edituser',
      state: { user_id: item.user_id, is_subscribe: item.is_subscriber }
    })
  }

  const handleSubscribeSubmit = (async () => {
    if (planValue == null || planValue == "") {
      await setPlanError(true);
    }
    else if (selectedTimePeriod == "") {
      setMonthSelectError(true)
    }
    else {
      await subscribeUser(context.setUsers, {
        "user_id": resetLinkData.user_id,
        "plan_id": planValue,
        "expire_date": subscriptionDate.end_date
      })
      await getUsersRequest(context.setUsers);
      setSubscribeModal(false);
      setSelectedTimePeriod("");
      setSubscriptionDate({
        start_date: "",
        end_date: ""
      });
      setPlanvalue('')
    }
  })


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event, data) => {
    setAnchorEl2(event.currentTarget);
    setResetLinkData(data);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const sendResetPassLink = async (data) => {
    handleClose2();
    const reqData = {
      email: data.email,
      is_admin_user: 1
    }
    await sendResetRequest(context.setUsers, reqData)
  }

  const handleSelectTimePeriod = (item) => {
    setSelectedTimePeriod(item);
    const newEndDate = new Date(new Date());
    newEndDate.setMonth(newEndDate.getMonth() + item.id);
    setSubscriptionDate({
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      end_date: moment(newEndDate).format('YYYY-MM-DD')
    });
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <span className={classes.mainPageTitle}>Users Management</span>
      <Grid item xs={12}>
        <Widget inheritHeight>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            {admintype !== '1' && <Box style={{ margin: "0 12px 0 0" }}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => onCreateUserClicked()}
              >
                <Box mr={1} display={'flex'}>
                  <AddIcon />
                </Box>
                Create User
              </Button>
            </Box>}
            <Box
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-end'}
              >
                <Input
                  id="search-field"
                  label="Search"
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => handleSearch(e)}
                  value={searchVal}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Widget>
      </Grid>
      {!context.users.isLoaded || !users.data ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"calc(100vh - 200px)"}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table">

                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={users.data.length}
                  admin={admintype}
                />
                {users.data.length > 0 ?
                  <TableBody>
                    {stableSort(users.data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          // style={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}
                            >
                              <Typography
                                variant={'body2'}
                              >
                                {(page * rowsPerPage) + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <Typography
                                  variant={'body2'}
                                >
                                  {row.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}>
                              <Box
                                display={"flex"}
                                flexWrap={"nowrap"}
                                alignItems={"center"}
                                style={{ height: '50px', width: '50px', margin: '0 auto' }}
                              >
                                <img src={row.profile_pic} className={classes.profilePicture} alt='profile' />
                              </Box>
                            </TableCell>
                            <TableCell style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}>{row.gender == 1 ? 'Male' : row.gender == 2 ? 'Female' : 'Other'}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}>{moment(row.date_of_birth).format("MM-DD-YYYY")}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}>{row.total_likes}</TableCell>
                            <TableCell align="left">
                              <Typography
                                variant={'body2'}
                              >
                                <Switch
                                  checked={row.is_banned === 0 ? true : false}
                                  onChange={(e) => handleChange(e, row.user_id)}
                                  color="primary"
                                  name="checkedB"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell style={{ cursor: 'pointer' }}
                              onClick={() => redirectToDetails(row.user_id)}>{moment(row.created_date * 1000).format("DD-MM-YYYY hh:mm:ss A")}
                            </TableCell>
                            <TableCell >
                              <div style={{ alignSelf: "center", width: "80%", }}>
                                <Button
                                  id="demo-positioned-button"
                                  color="primary"
                                  style={{ cursor: 'pointer', width: "100%" }}
                                  variant='contained'
                                  aria-controls={openDropdown2 ? 'demo-positioned-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openDropdown2 ? 'true' : undefined}
                                  onClick={(event) => handleClick2(event, row)}>
                                  <div style={{ width: '95%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "start" }}>
                                    Action
                                    <ArrowDropDown />
                                  </div>
                                </Button>
                                <Menu
                                  id="demo-positioned-menu"
                                  aria-labelledby="demo-positioned-button"
                                  anchorEl={anchorEl2}
                                  open={openDropdown2}
                                  onClose={handleClose2}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  <MenuItem>
                                    {
                                      resetLinkData && resetLinkData.is_subscriber == 0 ? <Button color={'primary'} variant={'outlined'} size={'medium'}
                                        onClick={() => (setSubscribeModal(true), handleClose2())}
                                      > Subscribe </Button>
                                        : <label
                                          style={{ color: 'green' }}>
                                          SUBSCRIBED
                                        </label>
                                    }
                                  </MenuItem>
                                  <MenuItem>
                                    <Button
                                      color={'primary'}
                                      variant={'outlined'}
                                      size={'medium'}
                                      onClick={() => sendResetPassLink(resetLinkData)}
                                    > Send Reset Password Link
                                    </Button>
                                  </MenuItem>
                                </Menu>
                              </div>
                            </TableCell>
                            {admintype !== '1' && <TableCell style={{ cursor: 'pointer' }}
                              onClick={() => onEditUserClicked(row)}
                            >
                              <Edit />
                            </TableCell>}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  :
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={admintype == 3 ? headCells2.length : headCells1.length}>
                        <Typography
                          style={{ textAlign: 'center', padding: '10px 0px' }}
                          noWrap
                          variant={'h4'}>No Record Found</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={config.defaultRowsPerPage}
              component="div"
              count={users.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}

      <Dialog
        open={subscribeModal}
        onClose={() => {
          setSubscribeModal(false);
          setPlanvalue('');
          setSelectedTimePeriod('');
          setSubscriptionDate({
            start_date: "",
            end_date: ""
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '25px' }}>Subscription</span>
        </DialogTitle>
        <DialogContent >
          <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#7B67ED' }}>Subscription Type</span>
          <div className={classes.modalInnerContainer}>
            <RadioGroup
              aria-labelledby="subscription-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={planValue}
              onChange={(e) => (setPlanvalue(e.target.value), setPlanError(false), setMonthSelectError(false))}
              style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}
            >
              <div>
                <FormControlLabel
                  value={'2'}
                  control={<Radio />}
                  label={
                    <Typography style={{ fontWeight: 'bold' }}>
                      Plus subscription
                    </Typography>} />
                <div className='plusplan' style={{ paddingLeft: '20px' }} >
                  <li >Upload 10 Photos Max</li>
                  <li>Upload 10 Audio Max</li>
                  <li>Upto 5 Matches/Day</li>
                  <li>Unlock 'Liked You' & 'Passed' View</li>
                </div>
              </div>
              <div>
                <FormControlLabel
                  value={'3'}
                  control={<Radio />}
                  label={
                    <Typography style={{ fontWeight: 'bold' }}>
                      Pro Subscription
                    </Typography>
                  }
                />
                <div className='plusplan' style={{ paddingLeft: '20px' }} >
                  <li >Upload 10 Photos Max</li>
                  <li>Upload 10 Audio Max</li>
                  <li>Unlimited Match</li>
                  <li>Unlock 'Liked You' & 'Passed' View</li>
                </div>
              </div>
            </RadioGroup>
            {planError == true ? <span style={{ color: 'red' }}>Plase Select Subscription Type</span> : ''}
            <>
              <div style={{ alignSelf: "center", width: "80%", marginTop: "20px" }}>
                <Button
                  id="demo-positioned-button"
                  color="primary"
                  style={{ cursor: 'pointer', width: "100%" }}
                  variant='contained'
                  aria-controls={openDropdown ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropdown ? 'true' : undefined}
                  onClick={planValue != null && handleClick}>
                  <div style={{ width: '95%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "start" }}>
                    Select Time Period :
                    {`${selectedTimePeriod?.name && selectedTimePeriod?.name?.length > 0 ? ` ${selectedTimePeriod.name}` : " 0 Month"}`}
                    <ArrowDropDown />
                  </div>
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={openDropdown}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {
                    month_array.map((item, index) => {
                      return (
                        <MenuItem style={{ width: "200px" }} onClick={() => handleSelectTimePeriod(item)}>{item.name}</MenuItem>
                      )
                    }
                    )
                  }
                </Menu>
                {monthSelectError == true ? <span style={{ color: 'red' }}>Plase Select Time Period</span> : ''}
                {/* {selectedTimePeriod?.name && selectedTimePeriod?.name?.length > 0 &&
                  <>
                  <div style={{ marginTop: 20, display: 'flex', flexDirection: "column" }}>
                      <label>Start Date</label>
                      <TextField
                        value={subscriptionDate.start_date}
                        type='date'
                        onChange={(e) => handleSubscriptionDate(e)}
                        inputProps={{
                          min: moment(new Date()).format('YYYY-MM-DD')
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, flexDirection: "column" }}>
                      <label>End Date</label>
                      <TextField
                        value={subscriptionDate.end_date}
                        type='date'
                        inputProps={{
                          min: moment(new Date()).format('YYYY-MM-DD')
                        }}
                        disabled={true}
                      />
                    </div>
                  </>
                } */}
              </div>
            </>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "10px 24px 20px" }}>
          <Button
            variant={"outlined"}
            color="primary"
            onClick={() => {
              setSubscribeModal(false);
              setPlanvalue('');
              setSelectedTimePeriod('');
              setSubscriptionDate({
                start_date: "",
                end_date: ""
              });
            }}
            style={{ cursor: 'pointer' }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            onClick={handleSubscribeSubmit}
            style={{ cursor: 'pointer' }}
          >
            Subscribe
          </Button>

        </DialogActions>
      </Dialog>
    </Grid >
  )
}

export default withRouter(Users)
